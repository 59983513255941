import { useRef, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FormattedMessage, defineMessage } from 'react-intl';
// TODO: move function to utils
import { formatDateRange } from 'design-system/molecules/date-range';
import { useIntercom } from 'react-use-intercom';
import { debounce } from 'lodash';
import SpinnerSuspense from 'design-system/atoms/spinner-suspense';
import { SortOption } from 'design-system/molecules/drop-down-sort';
import { ListItem } from 'design-system/molecules/list-item';
import SearchBar from 'design-system/organisms/search-bar';
import { ViewScorecard } from '@webapp/bff/src/types/report';
import { CompareSortValuesMode } from '../../utils/sort';
import useScorecardsData from '../../data/useScorecardsData';
import ScorecardPromo from './ScorecardPromo';
import LayoutPage from '../LayoutPage';
import TabsList from './TabsList';
import { pathScorecard } from '../../constants/path';
import { errorReport } from '../../utils/errors';

declare global {
  interface Window {
    analytics: any;
  }
}

const scorecardSortOptions: SortOption[] = [
  {
    sortOptionMessage: defineMessage({
      defaultMessage: 'Name',
      id: 'HAlOn1',
    }),
    value: 'name',
    enabled: true,
  },
  {
    sortOptionMessage: defineMessage({
      defaultMessage: 'Created',
      id: 'ORGv1Q',
    }),
    value: 'createdAt',
    enabled: true,
  },
  {
    sortOptionMessage: defineMessage({
      defaultMessage: 'Report date',
      id: 'U4gPot',
    }),
    value: 'from',
    enabled: true,
  },
];
type SortableScorecardField = Extract<
  keyof ViewScorecard,
  'name' | 'from' | 'createdAt'
>;
function IsSortableScorecardField(
  field: string
): field is SortableScorecardField {
  return ['name', 'from', 'createdAt'].includes(field);
}

function ScorecardList({
  scorecards,
}: {
  scorecards: {
    name: string;
    from: number;
    to: number;
    id: string;
    timezone: string;
  }[];
}) {
  if (scorecards.length === 0) {
    return <>No scorecard matching your search criteria</>;
  }
  return (
    <Box width="full">
      {scorecards.map((scorecard) => (
        <a
          key={scorecard.id}
          href={pathScorecard(scorecard.id)}
          aria-label="View scorecard"
          target="_blank"
          rel="noreferrer"
        >
          <ListItem
            title={scorecard.name}
            description={formatDateRange(
              new Date(scorecard.from),
              new Date(scorecard.to),
              scorecard.timezone
            )}
          />
        </a>
      ))}
    </Box>
  );
}

function ScorecardSideBar({
  searchTerm,
  insightId,
  selectedSortOption,
  selectedDirection,
}: {
  searchTerm: string;
  insightId: string;
  selectedSortOption: string;
  selectedDirection: CompareSortValuesMode;
}) {
  const intercom = useIntercom();
  const scoreCardsDataHandler = useScorecardsData(insightId);
  const dir = selectedDirection === CompareSortValuesMode.LOWEST_FIRST ? 1 : -1;
  const sorted = scoreCardsDataHandler.data.sort((s1, s2) =>
    selectedSortOption === 'name'
      ? dir * s1.name.localeCompare(s2.name)
      : dir * (s2[selectedSortOption] - s1[selectedSortOption])
  );
  const filtered = sorted.filter(
    (d) => d.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
  );
  return (
    <VStack overflowY="auto" px={4} alignItems="flex-start" flexGrow={1}>
      <Button
        size="sm"
        colorScheme="greenDark"
        mt="16px"
        onClick={() => intercom.startSurvey(33269271)}
      >
        <FormattedMessage
          defaultMessage="New scorecard"
          id="0JMyQ4"
          description="Homepage new scorecard button"
        />
      </Button>
      {sorted.length === 0 ? (
        <Text fontSize="sm">
          <FormattedMessage
            defaultMessage="You’re yet to create a scorecard. Try creating your first scorecard today!"
            id="BHtIj/"
          />
        </Text>
      ) : (
        <ScorecardList scorecards={filtered} />
      )}
    </VStack>
  );
}

export default function ScorecardsTab({ insightId }: { insightId: string }) {
  const [searchTerm, setSearchTerm] = useState('');

  const [selectedSortOption, setSelectedSortOption] =
    useState<SortableScorecardField>('createdAt');
  const [sortDirection, setSortDirection] = useState(
    CompareSortValuesMode.LOWEST_FIRST
  );
  const isMobile = useBreakpointValue({
    md: false,
    base: true,
  });
  const debouncedUseSearch = useRef(
    debounce(() => {
      window.analytics?.track('Scorecards Search Input Changed', {
        referrer: `NetworkMonitoring - scorecards`,
      });
    }, 5000)
  );
  return (
    <LayoutPage maxWidth="full">
      <Flex
        flexDirection="column"
        margin={{ base: '-6px -16px 0 -16px', md: '-8px -32px 0 -32px' }}
      >
        <Box h={isMobile ? '24px' : 'unset'} />
        <VStack alignItems="stretch" padding={4} spacing={4}>
          <TabsList insightId={insightId} overviewTab="scorecards" />
          <SearchBar
            searchTerm={searchTerm}
            setSearchTerm={(term) => {
              setSearchTerm(term);
              debouncedUseSearch.current();
            }}
            sortDirectionIncrease={
              sortDirection === CompareSortValuesMode.LOWEST_FIRST
            }
            setSortDirectionIncrease={(isIncrease) => {
              setSortDirection(
                isIncrease
                  ? CompareSortValuesMode.LOWEST_FIRST
                  : CompareSortValuesMode.HIGHEST_FIRST
              );
              window.analytics?.track('Scorecards List Direction Changed', {
                referrer: `NetworkMonitoring - scorecards`,
                direction: isIncrease
                  ? CompareSortValuesMode.LOWEST_FIRST
                  : CompareSortValuesMode.HIGHEST_FIRST,
              });
            }}
            selectedSortOption={selectedSortOption}
            setSelectedSortOption={(optionValue) => {
              if (!IsSortableScorecardField(optionValue)) {
                errorReport.handled(
                  new Error('Trying to sort scorecards by unsupported field'),
                  { fieldName: optionValue }
                );
                return;
              }
              setSelectedSortOption(optionValue);
              window.analytics?.track('Scorecards List Sorted', {
                referrer: `NetworkMonitoring - scorecards`,
                sortOption: optionValue,
              });
            }}
            sortOptions={scorecardSortOptions}
          />
        </VStack>
        <Flex mr={{ base: 0, md: 4 }} direction={{ base: 'column', md: 'row' }}>
          <SpinnerSuspense>
            <ScorecardSideBar
              insightId={insightId}
              searchTerm={searchTerm}
              selectedSortOption={selectedSortOption}
              selectedDirection={sortDirection}
            />
          </SpinnerSuspense>
          <Box
            maxWidth={{ base: '100%', md: '40%' }}
            mt={{ base: '16px', md: 0 }}
            p={{ base: 4, md: 0 }}
          >
            <ScorecardPromo />
          </Box>
        </Flex>
      </Flex>
    </LayoutPage>
  );
}
