import { useCallback, useMemo, useState } from 'react';
import { Box } from '@chakra-ui/react';
import performanceStatsOptions from '../../constants/performanceStatsOptions';
import { SiteOverviewModel, SummaryModel } from '../../types/site';
import {
  DataHandlerFeedback,
  hasDataHandlerFeedBack,
} from '../../utils/dataHandler';
import { compareSortValues, CompareSortValuesMode } from '../../utils/sort';
import HomepageMap from '../HomepageMap';
import LayoutNetworkMonitoring, {
  LAST_EDITED_OPTION_VALUE,
  NAME_OPTION_VALUE,
  lastEditedOption,
  nameOption,
} from './LayoutNetworkMonitoring';
import SitesTable from './SitesTable';
import BaseMap from '../BaseMap';
import WrapComponent from '../WrapComponent';
import useSitesData from '../../data/useSitesData';
import useOrgProjectsData from '../../data/useOrgProjectsData';

export default function MonitoringSiteProjects({
  insightId,
  showInactiveSites = true,
  Wrapper,
}: {
  insightId: string;
  showInactiveSites?: boolean;
  Wrapper: typeof LayoutNetworkMonitoring;
}) {
  const orgProjectsDataHandler = useOrgProjectsData({ orgId: insightId });
  const [searchTerm, setSearchTerm] = useState('');
  // const handleClearSearch = useCallback(
  //   () => setSearchTerm(''),
  //   [setSearchTerm]
  // );
  const [selectedSortOption, setSelectedSortOption] = useState(
    showInactiveSites ? LAST_EDITED_OPTION_VALUE : NAME_OPTION_VALUE
  );
  const [sortDirection, setSortDirection] = useState(
    showInactiveSites
      ? CompareSortValuesMode.HIGHEST_FIRST
      : CompareSortValuesMode.LOWEST_FIRST
  );
  // TODO: get showInactive from filters
  const [showInactive] = useState(false);
  const [selectedSiteId, setSelectedSiteId] = useState<number | null>(null);

  const siteDataHandler = useSitesData(insightId);
  const sortedSites = useMemo(
    () =>
      // TODO: add lastEdited to summary
      // @ts-ignore
      applySort(siteDataHandler.data || [], {
        sortBy: selectedSortOption,
        direction: sortDirection,
      }),
    [siteDataHandler.data, selectedSortOption, sortDirection]
  );
  const checkVisibility = useCallback(
    (summary: SiteOverviewModel): boolean => {
      if (!showInactiveSites && !showInactive && !summary.active) {
        return false;
      }
      return (
        !searchTerm
          ? true
          : (summary.siteName &&
              summary.siteName.toLowerCase().indexOf(searchTerm.toLowerCase()) >
                -1) ||
            (summary.siteAddress &&
              summary.siteAddress
                .toLowerCase()
                .indexOf(searchTerm.toLowerCase()) > -1) ||
            (summary.projectName &&
              summary.projectName
                .toLowerCase()
                .indexOf(searchTerm.toLowerCase()) > -1)
      ) as boolean;
    },
    [searchTerm, showInactive, showInactiveSites]
  );
  const enabledSortOptions =
    sortedSites.reduce(
      (all, s) =>
        all.concat(
          s.summaryModels
            ?.map((m) => m.summaryType!)
            ?.filter((t) => all.indexOf(t) < 0) || []
        ),
      [] as string[]
    ) || [];
  const sitesDataHandlerFeedBack = hasDataHandlerFeedBack(siteDataHandler);
  const mapComponentProps = useMemo(
    () => ({
      sites: siteDataHandler.data ?? [],
      selectedSiteId,
      checkVisibility: (summary) => {
        if (!showInactive && !summary.active) {
          return false;
        }
        return checkVisibility(summary);
      },
      setSelectedSiteId,
    }),
    [siteDataHandler.data, selectedSiteId, showInactive, checkVisibility]
  );
  const mapComponent = (
    <WrapComponent
      Wrapper={BaseMap}
      Component={HomepageMap}
      // TODO: add routes to summary
      // @ts-ignore
      componentProps={mapComponentProps}
    />
  );

  return (
    <Wrapper
      defaultOpen
      insightId={insightId}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      sortOptions={[
        nameOption,
        ...(showInactiveSites
          ? [lastEditedOption]
          : Object.values(performanceStatsOptions).map((o) => ({
              sortOptionMessage: (o as any).sortOptionMessage,
              value: (o as any).value,
              enabled: enabledSortOptions.indexOf((o as any).value) >= 0,
            }))),
      ]}
      selectedSortOption={selectedSortOption}
      setSelectedSortOption={setSelectedSortOption}
      sortDirection={sortDirection}
      setSortDirection={setSortDirection}
      sidebarContent={
        <Box flex="1" height="full" padding={4}>
          {sitesDataHandlerFeedBack ? (
            <DataHandlerFeedback dataHandlersParam={siteDataHandler} />
          ) : (
            <SitesTable
              insightId={insightId}
              projects={orgProjectsDataHandler.data ?? []}
              // checkVisibility={checkVisibility}
              // clearSearch={handleClearSearch}
              // searchTerm={searchTerm}
              // selectedSiteId={selectedSiteId}
              // setSelectedSiteId={setSelectedSiteId}
            />
          )}
        </Box>
      }
      mapContent={
        sitesDataHandlerFeedBack ? (
          <DataHandlerFeedback dataHandlersParam={siteDataHandler} />
        ) : (
          mapComponent
        )
      }
    />
  );
}

function getValueBySummaryType(type: string, summaries: SummaryModel[] = []) {
  const summaryMatch = summaries.find(
    (summary) => summary.summaryType === type
  );
  return summaryMatch?.value;
}

export function applyFilter(
  summaries: SiteOverviewModel[],
  params = {
    searchTerm: '',
  }
) {
  return !params.searchTerm
    ? summaries
    : summaries.filter(
        (summary) =>
          (summary.siteName &&
            summary.siteName
              .toLowerCase()
              .indexOf(params.searchTerm.toLowerCase()) > -1) ||
          (summary.siteAddress &&
            summary.siteAddress
              .toLowerCase()
              .indexOf(params.searchTerm.toLowerCase()) > -1) ||
          (summary.projectName &&
            summary.projectName
              .toLowerCase()
              .indexOf(params.searchTerm.toLowerCase()) > -1)
      );
}

export function applySort(
  summaries: SiteOverviewModel[],
  params: {
    sortBy: string;
    direction: CompareSortValuesMode;
  }
) {
  const filteredSorted = summaries.sort((summaryA, summaryB) => {
    if (params.sortBy === NAME_OPTION_VALUE) {
      return params.direction === CompareSortValuesMode.LOWEST_FIRST
        ? summaryA.siteName!.localeCompare(summaryB.siteName!)
        : -summaryA.siteName!.localeCompare(summaryB.siteName!);
    }
    if (params.sortBy === LAST_EDITED_OPTION_VALUE) {
      return params.direction === CompareSortValuesMode.LOWEST_FIRST
        ? summaryA.lastEdited - summaryB.lastEdited
        : summaryB.lastEdited - summaryA.lastEdited;
    }
    if (summaryA.active !== summaryB.active) {
      return summaryA.active ? -1 : 1;
    }
    if (summaryA.numberOfRoutes === 0 && summaryB.numberOfRoutes! > 0) {
      return -1;
    }
    if (summaryB.numberOfRoutes === 0 && summaryA.numberOfRoutes! > 0) {
      return 1;
    }
    const summaryValueA = getValueBySummaryType(
      params.sortBy,
      summaryA.summaryModels
    );
    const summaryValueB = getValueBySummaryType(
      params.sortBy,
      summaryB.summaryModels
    );
    return compareSortValues(summaryValueA, summaryValueB, params.direction);
  });
  return filteredSorted;
}
