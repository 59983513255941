import { memo, useCallback, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import { Center } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import type { DataType } from '@webapp/bff';
import useSiteView from '../../hooks/useSiteView';
import useInsightChartData, {
  SiteInsightData,
} from '../../data/useInsightChartData';
import {
  DataHandlerFeedback,
  hasDataHandlerFeedBack,
} from '../../utils/dataHandler';
import { useCurrentSiteData } from '../../data/useSiteData';
import useAnnotationsData from '../../data/useAnnotationsData';
import { processBlob } from '../../utils/processCSV';
import {
  getChartConfigFromChartType,
  InsightChartConfig,
} from '../../utils/insightCharts';
import { colorsSortedByKey } from '../../utils/routes';
import { Site } from '../../types/site';
import { TimePeriod } from '../../types/timePeriod';
import EmptyInsightChart from './EmptyInsightChart';
import LayoutPanel from '../LayoutPanel';
import useMemoChartDataFromSiteInsight from '../../hooks/useMemoChartDataFromSiteInsight';
import { CsvHeaders } from '../../types/csv';
import useIntlWeekDays from '../../hooks/useIntlWeekDays';
import { Annotation } from '../../types/annotations';
import useIncidentsData from '../../data/useIncidentsData';
import { getDateBoundaryByTimePeriod } from '../../data/useInsightSummaryData';
import { errorReport } from '../../utils/errors';
import { SiteImpactChartPanelContent } from './SiteImpactChartPanelContent';
import { ChartPanelContent } from './ChartPanelContent';
import useSiteBaselinesData from '../../data/useSiteBaselinesData';
import useFeatureSwitch, {
  FeatureSwitchState,
} from '../../hooks/useFeatureSwitch';
import { FEATURE_SITE_IMPACT_UI_BASELINES } from '../../constants/features';
import SiteImpactEmptyStatePanel from './SiteImpactEmptyStatePanel';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';

interface InsightChartPanelProps {
  insightId: string;
  dataType: DataType;
  hideIncidents?: boolean;
  onManageBaselinesClick?: () => void;
}

export default memo(SiteInsightChart);
function SiteInsightChart({
  insightId,
  dataType,
  hideIncidents,
  onManageBaselinesClick,
}: InsightChartPanelProps) {
  const { formatMessage, formatDate, formatTime } = useIntl();
  const { track } = useAnalytics();
  const weekdays = useIntlWeekDays('long');
  const siteViewState = useSiteView();
  const currentSiteDataHandler = useCurrentSiteData();
  const currentSite = currentSiteDataHandler.data;
  if (!siteViewState) {
    throw Error('no site selected for the charts');
  }
  const baselinesHandler = useSiteBaselinesData(
    currentSite?.siteId?.toString()
  );
  const [selectedByUserBaseline, setSelectedByUserBaseline] = useState<
    string | null
  >(null);
  let selectedBaselineId =
    selectedByUserBaseline ??
    (baselinesHandler.data?.baselines ?? []).find((b) => b.isDefault)
      ?.baselineId;
  if (!selectedBaselineId && baselinesHandler.data?.baselines?.length) {
    selectedBaselineId = baselinesHandler.data.baselines[0].baselineId;
  }
  const { state } = siteViewState;
  const chartDataHandler = useInsightChartData(
    !currentSite
      ? null
      : {
          insightId,
          siteId: state.siteId.toString(),
          baselineId: selectedBaselineId,
          dataType,
          siteTimeZone: currentSite.siteTimeZone,
          timePeriod: state.period,
          timePeriodReference: currentSite.active
            ? undefined
            : currentSite.endPollingDate,
        }
  );

  const annotationsDataHandler = useAnnotationsData(insightId, state.siteId);
  const incidentsDataHandler = useIncidentsData();
  const annotationsWithIncidents = useMemo(() => {
    if (hideIncidents || !incidentsDataHandler.data) {
      return annotationsDataHandler.data;
    }
    return [
      ...(annotationsDataHandler.data ?? []),
      ...incidentsDataHandler.data
        .filter((ins) => !state.hiddenIncidentCategories.includes(ins.type))
        .map((i) => ({
          title: i.description,
          startDate: i.startTime / 1000,
          endDate: i.startTime / 1000,
        })),
    ];
  }, [
    annotationsDataHandler.data,
    incidentsDataHandler.data,
    hideIncidents,
    state.hiddenIncidentCategories,
  ]);

  const chartConfig = useMemo(() => {
    if (chartDataHandler.data && currentSiteDataHandler.data) {
      return getChartConfigFromChartType(
        chartDataHandler.data.insightType,
        currentSiteDataHandler.data,
        formatMessage
      );
    }
    return {
      chartType: 'line',
      title: 'Chart data not loaded',
    } as InsightChartConfig;
  }, [chartDataHandler.data, currentSiteDataHandler.data, formatMessage]);

  const hiddenTrackIds = useMemo(
    () => state.hiddenRoutes.map((trackId) => `_${trackId}`),
    [state.hiddenRoutes]
  );
  const exportGraphCSVFE = useCallback(() => {
    if (chartDataHandler.data && currentSiteDataHandler.data) {
      exportChartCSVHandlerFE({
        chartData: chartDataHandler.data,
        timePeriod: state.period,
        chartTitle: chartConfig.title,
        dataType,
        formatTime,
        weekdays,
        hiddenTrackIdsParam: hiddenTrackIds,
        site: currentSiteDataHandler.data,
      });
    }
  }, [
    chartDataHandler.data,
    currentSiteDataHandler.data,
    state.period,
    chartConfig.title,
    dataType,
    formatTime,
    weekdays,
    hiddenTrackIds,
  ]);

  const exportAnnotationsFE = useCallback(() => {
    exportAnnotationsHandlerFE(
      annotationsDataHandler.data,
      formatDate,
      currentSiteDataHandler.data,
      state.period
    );
  }, [
    annotationsDataHandler.data,
    formatDate,
    currentSiteDataHandler.data,
    state.period,
  ]);

  const routeColorsByTrackId = useMemo(
    () => colorsSortedByKey(currentSiteDataHandler.data?.routes ?? [], 'id'),
    [currentSiteDataHandler.data?.routes]
  );

  const featureBaselinesUI = useFeatureSwitch(FEATURE_SITE_IMPACT_UI_BASELINES);
  const isFeatureBaselineUI = featureBaselinesUI === FeatureSwitchState.ON;

  const { isDataEmpty, isDataHeavy, formattedChartSeries } =
    useMemoChartDataFromSiteInsight(
      chartDataHandler.data ? chartDataHandler.data.routes : undefined,
      hiddenTrackIds,
      routeColorsByTrackId,
      chartConfig.chartType,
      !!state.period.compareDate
    );
  const is404 =
    chartDataHandler.error !== undefined &&
    ((chartDataHandler.error.message?.toLowerCase()?.indexOf('not found') ??
      -1) >= 0 ||
      chartDataHandler.error.message === '404');
  if (is404) {
    return null;
  }
  if (hasDataHandlerFeedBack([chartDataHandler, currentSiteDataHandler])) {
    return (
      <LayoutPanel data-testid="view-site-chart-panel-loading">
        <Center height="400px">
          <DataHandlerFeedback
            dataHandlersParam={[chartDataHandler, currentSiteDataHandler]}
          />
        </Center>
      </LayoutPanel>
    );
  }
  if (!chartDataHandler.data || !currentSiteDataHandler.data) {
    return null;
  }
  if (baselinesHandler.error) {
    errorReport.critical('Error getting baselines for site', {
      err: baselinesHandler.error,
      siteId: currentSiteDataHandler.data.siteId,
    });
  }
  const isSiteImpactChart =
    isFeatureBaselineUI && dataType === 'SITE_IMPACT_DELAY';
  if (
    isSiteImpactChart &&
    baselinesHandler.data &&
    baselinesHandler.data.baselines.length === 0
  ) {
    return (
      <SiteImpactEmptyStatePanel
        variant={
          baselinesHandler.data.baselineRequests.length === 0
            ? 'empty'
            : 'recording'
        }
        onManageBaselinesClick={() => {
          if (onManageBaselinesClick) {
            onManageBaselinesClick();
            track('Manage Baselines Clicked', {
              referrer: 'Site Impact Empty Panel',
              feature: 'Baselines',
              variant:
                baselinesHandler.data?.baselineRequests.length === 0
                  ? 'empty'
                  : 'recording',
            });
          }
        }}
      />
    );
  }
  return (
    <LayoutPanel
      padding="0px"
      data-testid={`view-site-chart-panel-${chartDataHandler.data.insightType}`}
    >
      {isDataEmpty && <EmptyInsightChart title={chartConfig.title} />}
      {!isDataEmpty && isSiteImpactChart && (
        <SiteImpactChartPanelContent
          key={selectedBaselineId}
          selectedBaselineId={selectedBaselineId}
          setSelectedBaselineId={setSelectedByUserBaseline}
          baselines={baselinesHandler.data?.baselines ?? []}
          onManageBaselinesClick={() => {
            if (onManageBaselinesClick) {
              onManageBaselinesClick();
              track('Manage Baselines Clicked', {
                referrer: 'Site Impact Chart Panel',
                feature: 'Baselines',
                variant:
                  baselinesHandler.data?.baselineRequests.length === 0
                    ? 'empty'
                    : 'recording',
              });
            }
          }}
          timeZone={currentSiteDataHandler.data.siteTimeZone}
          chartConfig={chartConfig}
          chartSeries={formattedChartSeries}
          exportGraphCSV={exportGraphCSVFE}
          exportAnnotations={exportAnnotationsFE}
          annotations={annotationsWithIncidents}
          shouldAnimate={!isDataHeavy}
          startTime={chartDataHandler.data.startTime}
          endTime={chartDataHandler.data.endTime}
          compareDate={state.period.compareDate}
          createAnnotation={annotationsDataHandler.createAnnotation}
          resendAnnotationAlert={annotationsDataHandler.resendAnnotationAlert}
        />
      )}
      {!isDataEmpty && !isSiteImpactChart && (
        <ChartPanelContent
          timeZone={currentSiteDataHandler.data.siteTimeZone}
          chartConfig={chartConfig}
          chartSeries={formattedChartSeries}
          exportGraphCSV={exportGraphCSVFE}
          exportAnnotations={exportAnnotationsFE}
          annotations={annotationsWithIncidents}
          shouldAnimate={!isDataHeavy}
          startTime={chartDataHandler.data.startTime}
          endTime={chartDataHandler.data.endTime}
          compareDate={state.period.compareDate}
          createAnnotation={annotationsDataHandler.createAnnotation}
          resendAnnotationAlert={annotationsDataHandler.resendAnnotationAlert}
        />
      )}
    </LayoutPanel>
  );
}

interface CreateCSWRowOptions {
  item: SiteInsightData['routes'][number]['items'][number];
  compareItem?: SiteInsightData['routes'][number]['items'][number];
  route: SiteInsightData['routes'][number];
  timePeriod?: TimePeriod;
  formatTime: (
    date: Date | number,
    options?: Intl.DateTimeFormatOptions
  ) => string;
  weekdays: Array<{ value: number; label: string }>;
  site?: Site;
}

/**
 * Creates a CSV row from the provided item, route, and time period.
 */
function createCSVRow({
  item,
  compareItem,
  route,
  timePeriod,
  formatTime,
  weekdays,
  site,
}: CreateCSWRowOptions): string {
  const date = new Date(item.date);
  let compareDate = timePeriod?.compareDate
    ? new Date(timePeriod?.compareDate)
    : '';

  if (
    timePeriod?.type === 'TIME_PERIOD_CUSTOM' &&
    compareDate &&
    timePeriod?.startDate
  ) {
    const startDate = new Date(timePeriod.startDate);
    const diff = date.getTime() - startDate.getTime();
    compareDate = new Date(
      (typeof compareDate === 'string'
        ? new Date(compareDate).getTime()
        : compareDate.getTime()) + diff
    );
  } else if (
    timePeriod?.type === 'TIME_PERIOD_LAST_24_HOURS' &&
    compareDate instanceof Date
  ) {
    // Calculate the difference in milliseconds
    const diff = date.getTime() - compareDate.getTime();

    // If the difference is more than 24 hours, adjust the compare date
    if (diff > 24 * 60 * 60 * 1000) {
      compareDate = new Date(date.getTime() - 24 * 60 * 60 * 1000);
    }
  }

  const compareDayOfWeek =
    compareDate &&
    weekdays[
      typeof compareDate === 'string'
        ? new Date(compareDate).getDay()
        : compareDate.getDay()
    ].label;
  const dayOfWeek = weekdays[date.getDay()].label;
  const formattedDate = moment(date)
    .tz(site?.siteTimeZone ?? 'UTC')
    .format('D-MMM-yy');
  const formattedCompareDate =
    compareDate &&
    moment(compareDate)
      .tz(site?.siteTimeZone ?? 'UTC')
      .format('D-MMM-yy');
  const formattedTime = formatTime(date, {
    hourCycle: 'h23',
    timeZone: site?.siteTimeZone,
  });

  let row = `"${formattedDate}",${dayOfWeek},`;
  row += `"${formattedCompareDate}",${compareDayOfWeek},`;
  row += `"${formattedTime}",${route.name},`;
  row += `${item.value ?? ''},${compareItem?.value ?? ''}\n`;

  return row;
}

interface ExportChartCSVHandlerFEOptions {
  chartData: SiteInsightData;
  timePeriod?: TimePeriod;
  chartTitle: string;
  dataType: DataType;
  formatTime: (
    date: Date | number,
    options?: Intl.DateTimeFormatOptions
  ) => string;
  weekdays: Array<{ value: number; label: string }>;
  hiddenTrackIdsParam?: string[];
  site?: Site;
}

/**
 * Exports chart data to a CSV file.
 *
 * This function creates a CSV file with headers and rows based on the provided chart data.
 * Each row includes the date, day of the week, comparison date, comparison day, time, route name,
 * and the value and comparison value for the specified data type. The CSV file is then processed
 * and saved with a filename based on the chart title, data type, and current date and time.
 */
function exportChartCSVHandlerFE({
  chartData,
  timePeriod,
  chartTitle,
  dataType,
  formatTime,
  weekdays,
  hiddenTrackIdsParam,
  site,
}: ExportChartCSVHandlerFEOptions): void {
  if (!site || !timePeriod) {
    return;
  }
  const headers: CsvHeaders = {
    SPEED: 'Speed,Comparison_Speed',
    JOURNEY_TIME: 'Journey,Comparison_Journey',
    TIME_DELAY: 'Delay,Comparison_Delay',
    SITE_IMPACT_DELAY: 'Site_Impact_Delay,Comparison_Site_Impact_Delay',
    QUEUE_LENGTH: 'Queue_Length,Comparison_Queue_Length',
    COMPLETED_TRIP_TIME: 'Completed_Trip_Time,Comparison_Completed_Trip_Time',
    VEHICLE_COUNT: 'Vehicle_Count,Comparison_Vehicle_Count',
    DUST: 'Dust,Comparison_Dust',
    DWELL_TIME: 'Dwell_Time,Comparison_Dwell_Time',
    ENVIRONMENT: 'Environment,Comparison_Environment',
    NOISE: 'Noise,Comparison_Noise',
    RAIN: 'Rain,Comparison_Rain',
  };

  let csvContent = 'Date,Day,Comparison_Date,Comparison_Day,Time,Route_Name,';

  csvContent += `${headers[dataType]}\n`;

  chartData.routes.forEach((route) => {
    if (!hiddenTrackIdsParam?.includes(route.trackId.toString())) {
      route.items.forEach((item, itemIndex) => {
        csvContent += createCSVRow({
          item,
          compareItem: route.compareItems?.[itemIndex],
          route,
          timePeriod,
          formatTime,
          weekdays,
          site,
        });
      });
    }
  });

  const blob = new Blob([csvContent], { type: 'text/csv' });
  processBlob(
    blob,
    `Mooven_${site.siteName}_${chartTitle}-${new Date().toISOString()}.csv`
  );
}

/**
 * Helper function to format a new date using the formatDate from INTL.
 */
function formatAnnotationDate(date: number, timeZone: string, formatDate) {
  return formatDate(new Date(date * 1000), {
    timeZone,
    day: 'numeric',
    month: 'short',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
}

function exportAnnotationsHandlerFE(
  annotations: Annotation[],
  formatDate,
  site?: Site,
  timePeriod?: TimePeriod
): void {
  if (!site || !timePeriod) {
    return;
  }
  const headers = [
    'Start_Date',
    'End_Date',
    'Title',
    'Description',
    'Create_Date',
    'Author',
  ];

  const { startDateEpoch, endDateEpoch } =
    getDateBoundaryByTimePeriod(timePeriod);

  // Filter annotations to be exported by date range
  const filteredAnnotations = annotations.filter((annotation) => {
    const startDate = annotation.startDate ? annotation.startDate * 1000 : 0;
    const endDate = annotation.endDate ? annotation.endDate * 1000 : 0;
    return startDate >= startDateEpoch * 1000 && endDate <= endDateEpoch * 1000;
  });

  const csvContent = filteredAnnotations
    .map((annotation) => {
      const { title, description, startDate, endDate, createDate, ownerName } =
        annotation;

      const formattedCreateDate = createDate
        ? formatAnnotationDate(createDate, site.siteTimeZone, formatDate)
        : '';

      const formattedDate = startDate
        ? formatAnnotationDate(startDate, site.siteTimeZone, formatDate)
        : '';
      const formattedEndDate = endDate
        ? formatAnnotationDate(endDate, site.siteTimeZone, formatDate)
        : '';

      return `${formattedDate},${formattedEndDate},${title},${description},${formattedCreateDate},${ownerName}`;
    })
    .join('\n');
  const csv = `${headers.join(',')}\n${csvContent}`;
  const blob = new Blob([csv], { type: 'text/csv' });
  processBlob(blob, `annotations-${new Date().toISOString()}.csv`);
}
