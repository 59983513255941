import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { pathSitesOverview } from '../../constants/path';
import MonitoringSites from './MonitoringSites';
import MonitoringSiteProjects from './MonitoringSiteProjects';
import MonitoringRoutes from './MonitoringRoutes';
import RouteDetailModal from './RouteDetailModal';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import ScorecardsTab from './ScorecardsTab';
import useInsightData from '../../data/useInsightData';
import { hasDataHandlerFeedBack } from '../../utils/dataHandler';
import LayoutNetworkMonitoring from './LayoutNetworkMonitoring';
import WrapComponent from '../WrapComponent';
import useFeatureSwitch, {
  FeatureSwitchState,
} from '../../hooks/useFeatureSwitch';
import { FEATURE_PROJECTS_TABLE } from '../../constants/features';

export default function HomePage() {
  const { insightId, overviewTab, detailId } = useParams();
  const navigate = useNavigate();

  const featureProjectsTable = useFeatureSwitch(FEATURE_PROJECTS_TABLE);
  const isFeatureProjectsTable = featureProjectsTable === FeatureSwitchState.ON;

  const { track } = useAnalytics();
  const insightData = useInsightData(insightId);
  useEffect(() => {
    if (detailId !== undefined) {
      track('Route Detail Modal Opened', {
        referrer: 'Homepage Routes Tab',
        trackId: detailId,
        projectId: insightId,
      });
    }
  }, [detailId, insightId, track]);
  const closeDetailModal = () => {
    if (overviewTab === 'routes' || overviewTab === 'sites') {
      navigate(pathSitesOverview(insightId, overviewTab, null));
    }
  };
  if (hasDataHandlerFeedBack([insightData])) {
    return null;
  }

  let currentTabContent;
  switch (overviewTab) {
    case 'scorecards':
      currentTabContent = <ScorecardsTab insightId={insightId!} />;
      break;
    case 'routes':
      currentTabContent = (
        <WrapComponent
          Wrapper={LayoutNetworkMonitoring}
          Component={MonitoringRoutes}
          componentProps={{ insightId: insightId! }}
        />
      );
      break;
    case 'site-projects':
      currentTabContent = (
        <WrapComponent
          Wrapper={LayoutNetworkMonitoring}
          Component={MonitoringSiteProjects}
          componentProps={{ insightId: insightId! }}
        />
      );
      break;
    case 'sites-performance':
      currentTabContent = (
        <WrapComponent
          Wrapper={LayoutNetworkMonitoring}
          Component={MonitoringSites}
          componentProps={{ insightId: insightId! }}
        />
      );
      break;
    default:
      currentTabContent = (
        <WrapComponent
          Wrapper={LayoutNetworkMonitoring}
          Component={
            isFeatureProjectsTable ? MonitoringSiteProjects : MonitoringSites
          }
          componentProps={{ insightId: insightId! }}
        />
      );
  }
  return (
    <>
      {currentTabContent}
      <RouteDetailModal
        insightId={insightId!}
        trackId={detailId ?? ''}
        isOpen={!!detailId}
        onClose={closeDetailModal}
      />
    </>
  );
}
