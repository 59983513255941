import { ListItem } from 'design-system/molecules/list-item';
import { Status } from 'design-system/atoms/status';
import type { ProviderMessage } from '@configuration/lib-vms/src/lib/integrationTypes';
import { useIntl, FormattedRelativeTime, FormattedMessage } from 'react-intl';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import { ShowIcon } from 'design-system/atoms/custom-icons';
import { isPast } from 'date-fns';
import ContextMenu, {
  ContextMenuItemConfig,
} from 'design-system/molecules/context-menu';
import { DATE_PRESET_SHORT_D_M_Y } from '../../utils/dateUtils';

export interface VmsCardProps {
  contextMenuItems?: ContextMenuItemConfig[];
  vmsColor: string;
  vmsName: string;
  lastUpdatedDate?: Date;
  scheduleOffDate?: Date;
  scheduleOnDate?: Date;
  isRoutePolling: boolean;
  providerMessage?: ProviderMessage;
  onOpenPreview?: () => void;
  status: 'Active' | 'Past' | 'Future' | 'Draft' | 'Deleted' | 'Cooldown';
}
const lostConnectionThreshold = 3600;

export default function VmsCard({
  contextMenuItems,
  status,
  vmsColor,
  vmsName,
  lastUpdatedDate,
  scheduleOffDate,
  scheduleOnDate,
  isRoutePolling,
  onOpenPreview = () => {},
  providerMessage,
}: VmsCardProps) {
  const { formatMessage, formatDate } = useIntl();
  const secondsSinceLastUpdated = lastUpdatedDate
    ? Math.floor((new Date().getTime() - lastUpdatedDate.getTime()) / 1000)
    : -1;

  const getDescription = () => {
    switch (status) {
      case 'Active':
        if (
          secondsSinceLastUpdated !== -1 &&
          secondsSinceLastUpdated < lostConnectionThreshold
        ) {
          return (
            <>
              {formatMessage({ defaultMessage: 'Last updated', id: '0ICwq5' })}{' '}
              <FormattedRelativeTime
                value={-secondsSinceLastUpdated}
                numeric="auto"
                updateIntervalInSeconds={1}
              />
            </>
          );
        }
        if (secondsSinceLastUpdated > lostConnectionThreshold) {
          return (
            <Status
              data-ignore-visual-test
              warningStatus={
                <>
                  {formatMessage({
                    defaultMessage: 'Last updated',
                    id: '0ICwq5',
                  })}{' '}
                  <FormattedRelativeTime
                    value={-secondsSinceLastUpdated}
                    numeric="auto"
                    updateIntervalInSeconds={1}
                  />
                  {isRoutePolling
                    ? formatMessage({
                        defaultMessage: ': Connection lost',
                        id: 'FDcQJj',
                      })
                    : formatMessage({
                        defaultMessage: ': Route not recording',
                        id: 'SGWBD3',
                      })}
                </>
              }
            />
          );
        }
        return (
          <Status
            data-ignore-visual-test
            warningStatus={
              <>
                <FormattedMessage defaultMessage="Not connected" id="PuU15u" />
                {isRoutePolling ? ': Connection lost' : ': Route not recording'}
              </>
            }
          />
        );
      case 'Draft':
        return formatMessage({
          defaultMessage: 'Draft',
          id: 'W6nwjo',
        });
      default:
        break;
    }
    return '';
  };

  const getCalendarStatus = () => {
    if (scheduleOffDate && isPast(scheduleOffDate)) {
      return formatMessage(
        {
          defaultMessage: 'Schedule completed {turnOffDate}',
          id: 'Uj3O0i',
        },
        {
          turnOffDate: formatDate(scheduleOffDate, {
            ...DATE_PRESET_SHORT_D_M_Y,
          }),
        }
      );
    }
    if (scheduleOffDate && scheduleOnDate && !isPast(scheduleOnDate)) {
      return formatMessage(
        {
          defaultMessage: 'Scheduled {turnOnDate} - {turnOffDate}',
          id: '5q+BbE',
        },
        {
          turnOnDate: formatDate(scheduleOnDate, {
            ...DATE_PRESET_SHORT_D_M_Y,
          }),
          turnOffDate: formatDate(scheduleOffDate, {
            ...DATE_PRESET_SHORT_D_M_Y,
          }),
        }
      );
    }
    if (scheduleOffDate && !isPast(scheduleOffDate)) {
      return formatMessage(
        {
          defaultMessage: 'Scheduled to turn off {turnOffDate}',
          id: 'MjbsRN',
        },
        {
          turnOffDate: formatDate(scheduleOffDate, {
            ...DATE_PRESET_SHORT_D_M_Y,
          }),
        }
      );
    }
    if (scheduleOnDate && !isPast(scheduleOnDate)) {
      return formatMessage(
        {
          defaultMessage: 'Scheduled to turn on {turnOnDate}',
          id: 'YU9/jv',
        },
        {
          turnOnDate: formatDate(scheduleOnDate, {
            ...DATE_PRESET_SHORT_D_M_Y,
          }),
        }
      );
    }
    return '';
  };

  const getProviderWarning = () => {
    if (providerMessage?.warningMessage) {
      switch (providerMessage.warningMessage) {
        case 'Connection to board failed.  The board Id is either incorrect or the board is unavailable':
          return formatMessage({
            defaultMessage:
              'Connection to board failed.  The board Id is either incorrect or the board is unavailable',
            id: 'BVdFmi',
          });
        case 'Invalid username, password or board id':
          return formatMessage({
            defaultMessage: 'Invalid username, password or board id',
            id: 'fZtRnB',
          });
        case 'Unable to access Bartco. Please check that Bartco cross-hire is complete':
          return formatMessage({
            defaultMessage:
              'Unable to access Bartco. Please check that Bartco cross-hire is complete',
            id: '3on4gr',
          });
        case 'Your board is currently offline.':
          return formatMessage({
            defaultMessage: 'Your board is currently offline',
            id: 'woa1E8',
          });
        default:
          return formatMessage({
            defaultMessage: 'Unable to connect to the board',
            id: 'ngurEz',
          });
      }
    }
    return undefined;
  };

  return (
    <ListItem
      colorMode={status === 'Past' || status === 'Draft' ? 'dark' : 'light'}
      title={vmsName}
      description={
        <Flex direction="column" width="full">
          {getDescription()}
          <Status
            data-ignore-visual-test
            calendarStatus={getCalendarStatus()}
            warningStatus={getProviderWarning()}
          />
        </Flex>
      }
      content={
        <Box
          position="absolute"
          left="6px"
          top="6px"
          width="16px"
          height="6px"
          borderRadius="3px"
          backgroundColor={vmsColor}
        />
      }
      rightActions={
        <>
          {contextMenuItems && (
            <Box data-testid="vms-options">
              <ContextMenu items={contextMenuItems} />
            </Box>
          )}
          <IconButton
            variant="outline"
            onClick={onOpenPreview}
            aria-label="Show"
            icon={<ShowIcon />}
            size="sm"
            data-testid="vms-preview"
          />
        </>
      }
    />
  );
}
