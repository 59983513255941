export const FEATURE_PUBLIC_VIEW_SITE_IMPACT =
  'feature-public-view-site-impact';
export const FEATURE_SITE_IMPACT_KPI_ALERTS = 'site-impact-kpi-alerts';
export const FEATURE_CONFIGURE_QUEUEING_SITE_IMPACT =
  'configure-queueing-site-impact';
export const FEATURE_PLAN_INSIGHT_TYPE_SELECTOR =
  'feature-plan-insight-type-selector';
export const FEATURE_DATE_RANGE_V2 = 'refactor-date-range-selection-ui';
export const FEATURE_SHOW_INCIDENTS_VIEW_SITE =
  'feature-show-incidents-view-site';
export const FEATURE_VMS_ENABLED_TO_CUSTOMER =
  'feature-vms-enabled-to-customer';
export const FEATURE_SITE_IMPACT_UI_BASELINES =
  'feature-site-impact-ui-baselines';
export const FEATURE_SITE_IMPACT_UI_BASELINES_PHASE_2 =
  'feature-site-impact-ui-baselines--phase-2';
export const TEMP_WORK_SHIFT_MAP_ICONS = 'temp-work-shift-map-icons';
export const FEATURE_IMPACT_ASSESSMENT_WIZARD =
  'feature-impact-assessment-wizard';
export const FEATURE_PROJECTS_TABLE = 'feature-projects-table';
