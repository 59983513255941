import { Flex, Heading } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { DataGrid } from 'design-system/atoms/data-grid';
import { ColDef } from 'ag-grid-community';
import { DATE_PRESET_SHORT_D_M_Y } from 'design-system/utilities/date-presets';
import { StarIcon } from '@chakra-ui/icons';
import { pathSitesView } from '../../constants/path';
import { getColorBySiteId } from '../../utils/colors';
import { ProjectItem } from '../../data/useOrgProjectsData';

export default function SitesTable({
  projects,
  insightId,
  // clearSearch,
  // searchTerm,
  // selectedSiteId,
  // setSelectedSiteId,
  // checkVisibility,
}: {
  projects: ProjectItem[];
  insightId: string;
  // clearSearch: () => void;
  // searchTerm: string;
  // selectedSiteId: number | null;
  // setSelectedSiteId: (siteId: number | null) => void;
  // checkVisibility: (summary: SiteOverviewModel) => boolean;
}) {
  const { formatMessage, formatDate } = useIntl();
  const tableColumns: ColDef<ProjectItem>[] = useMemo(
    () => [
      {
        headerName: formatMessage({ defaultMessage: 'Site', id: 'pYwSd7' }),
        valueGetter: ({ data }) =>
          data && `${data?.site.siteName} - ${data?.site.siteAddress}`,
        autoHeight: true,
        minWidth: 200,
        flex: 1,
        cellRenderer: ({ value, data }) => (
          <Flex alignItems="center" flex={1} alignContent="center">
            <StarIcon color={getColorBySiteId(data?.site.siteId)} />
            <Link to={pathSitesView(insightId, data?.site.siteId)}>
              <Heading size="sm" ml={2}>
                {value}
              </Heading>
            </Link>
          </Flex>
        ),
      },
      {
        field: 'workArea',
        headerName: formatMessage({ defaultMessage: 'm²', id: 'WbpAvu' }),
        minWidth: 100,
      },
      {
        headerName: formatMessage({ defaultMessage: 'Start', id: 'mOFG3K' }),
        cellDataType: 'date',
        valueGetter: ({ data }) =>
          data && data.phases.length > 0
            ? new Date(data.phases[0]?.startTime)
            : undefined,
        valueFormatter: ({ value, data }) =>
          formatDate(value, {
            timeZone: data?.site.siteTimeZone,
            ...DATE_PRESET_SHORT_D_M_Y,
          }),
      },
      {
        headerName: formatMessage({ defaultMessage: 'End', id: '3JVa6k' }),
        cellDataType: 'date',
        valueGetter: ({ data }) =>
          data && data.phases.length > 0
            ? new Date(data.phases[data.phases.length - 1]?.endTime)
            : undefined,
        valueFormatter: ({ value, data }) =>
          formatDate(value, {
            timeZone: data?.site.siteTimeZone,
            ...DATE_PRESET_SHORT_D_M_Y,
          }),
      },
      {
        headerName: formatMessage({ defaultMessage: 'Duration', id: 'IuFETn' }),
        valueGetter: ({ data }) => getOccupationDurationDays(data),
        valueFormatter: ({ value }) =>
          formatMessage(
            { defaultMessage: '{durationDays} days', id: 'Vcp/ZW' },
            { durationDays: value }
          ),
      },
    ],
    [formatDate, formatMessage, insightId]
  );

  return <DataGrid rowData={projects} columnDefs={tableColumns} />;
}

function getOccupationDurationDays(projectItem?: ProjectItem) {
  if (!projectItem || !projectItem.phases.length) {
    return undefined;
  }
  const duration =
    projectItem.phases[projectItem.phases.length - 1].endTime -
    projectItem.phases[0].startTime;
  const durationDays = Math.floor(duration / (1000 * 60 * 60 * 24));
  return durationDays;
}
