import { Box, VStack } from '@chakra-ui/react';

import { useIntl } from 'react-intl';
import LayoutEmpty from 'design-system/molecules/layout-empty';
import { RouteSummary } from '../../data/useOrgRoutes';
import NoRoutes from '../../svg/tipped-cone.svg?react';
import RouteCard from './RouteCard';

export interface RoutesListProps {
  routes: RouteSummary[];
  searchTerm: string;
  onHighlightedTrackIdChange: (trackId: string | undefined) => void;
}

export default function RoutesList({
  routes,
  searchTerm,
  onHighlightedTrackIdChange,
}: RoutesListProps): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <VStack px={4}>
      {routes.length === 0 ? (
        <LayoutEmpty
          title={formatMessage(
            {
              defaultMessage:
                'We couldn\'t find any routes matching "{searchTerm}"',
              id: 'PQ8rEf',
              description: 'Empty search results for route list title',
            },
            { searchTerm }
          )}
          text={formatMessage({
            defaultMessage:
              'Double check your search for any spelling errors and try again',
            id: 'g1WjGC',
            description: 'Empty search results for route list text',
          })}
          image={<NoRoutes />}
        />
      ) : (
        <Box width="full">
          {routes.map((route) => (
            <RouteCard
              key={route.trackId}
              route={route}
              onMouseEnter={() => onHighlightedTrackIdChange(route.trackId)}
              onMouseLeave={() => onHighlightedTrackIdChange(undefined)}
            />
          ))}
        </Box>
      )}
    </VStack>
  );
}
