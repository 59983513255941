import { Box, Button, HStack, VStack } from '@chakra-ui/react';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LayoutEmpty from 'design-system/molecules/layout-empty';
import { SiteOverviewModel } from '../../types/site';
import SitePerformanceOverview from '../SitePerformanceOverview';
import TippedConeSvg from '../../svg/tipped-cone.svg?react';
import CreateSiteTrigger from '../CreateSiteTrigger';

export default function SitesList({
  sites,
  insightId,
  clearSearch,
  searchTerm,
  selectedSiteId,
  setSelectedSiteId,
  checkVisibility,
}: {
  sites: SiteOverviewModel[];
  insightId: string;
  clearSearch: () => void;
  searchTerm: string;
  selectedSiteId: number | null;
  setSelectedSiteId: (siteId: number | null) => void;
  checkVisibility: (summary: SiteOverviewModel) => boolean;
}) {
  const visibleNumber = useMemo(
    () => sites.filter((s) => checkVisibility(s)).length,
    [sites, checkVisibility]
  );
  return (
    <VStack px={4}>
      {sites.length === 0 && <NoSitesMessage insightId={insightId} />}
      {sites.length > 0 && visibleNumber === 0 && (
        <EmptySearchMessage
          insightId={insightId}
          searchTerm={searchTerm}
          clearSearch={clearSearch}
        />
      )}
      {sites.map((summary) => (
        <Box
          key={summary.siteId}
          display={checkVisibility(summary) ? undefined : 'none'}
          flex="0 0 auto"
          width="full"
          onMouseEnter={() => setSelectedSiteId(summary.siteId!)}
          onMouseLeave={() => {
            if (selectedSiteId === summary.siteId) {
              setSelectedSiteId(null);
            }
          }}
        >
          <SitePerformanceOverview
            key={summary.siteId}
            siteUnit={summary.siteUnitType!}
            insightId={parseInt(insightId, 10)}
            siteId={summary.siteId!}
            siteName={summary.siteName!}
            siteTimeZone={summary.siteTimeZone!}
            siteAddress={summary.siteAddress!}
            numberOfRoutes={summary.numberOfRoutes!}
            futureScheduleStartDate={summary.futureScheduleStartDate!}
            active={summary.active ?? false}
            currentScheduleEndDate={summary.currentScheduleEndDate!}
            isSelected={selectedSiteId === summary.siteId}
          />
        </Box>
      ))}
    </VStack>
  );
}

function EmptySearchMessage({
  insightId,
  searchTerm,
  clearSearch,
}: {
  insightId: string;
  searchTerm: string;
  clearSearch: () => void;
}) {
  const { formatMessage } = useIntl();
  return (
    <LayoutEmpty
      title={formatMessage(
        {
          defaultMessage: 'We couldn\'t find any sites matching "{searchTerm}"',
          id: 'klSY/a',
          description: 'Empty search results for site list title',
        },
        { searchTerm }
      )}
      text={formatMessage({
        defaultMessage:
          'Double check your search for any spelling errors and try again',
        id: 'n5zaQb',
        description: 'Empty search results for site list text',
      })}
      image={<TippedConeSvg />}
    >
      <HStack w="full" justifyContent="center">
        <CreateSiteTrigger insightId={insightId} />
        <Button variant="outline" onClick={clearSearch}>
          <FormattedMessage
            defaultMessage="Clear search"
            id="NWMNJO"
            description="Clear search in sites list"
          />
        </Button>
      </HStack>
    </LayoutEmpty>
  );
}

function NoSitesMessage({ insightId }: { insightId: string }) {
  const { formatMessage } = useIntl();
  return (
    <LayoutEmpty
      title={formatMessage({
        defaultMessage: 'We can’t find any sites that you have access to',
        id: 'UMIywA',
        description: 'No sites title for home page site list',
      })}
      text={formatMessage({
        defaultMessage:
          'Check in with your team to make sure you have been invited correctly, or let us know if you need help.',
        id: 'rHupzw',
        description: 'No sites description for home page site list',
      })}
      image={<TippedConeSvg />}
    >
      <HStack
        w="full"
        justifyContent="center"
        data-testid="empty-project-message"
      >
        <CreateSiteTrigger insightId={insightId} />
      </HStack>
    </LayoutEmpty>
  );
}
