import { useContext, useEffect, useState } from 'react';
import { runIfFn } from '@chakra-ui/utils';
import { Polyline } from '@react-google-maps/api';
import { MapTestModeContext } from 'design-system/atoms/custom-google-map';
import LatLngLiteral = google.maps.LatLngLiteral;
import { RouteSummary } from '../../data/useOrgRoutes';
import { Route } from '../../types/route';
import { parseWkt } from '../../utils/routes';

export const ANIMATION_ZOOM_THRESHOLD = 14.5;
export const ANIM_BASE_SPEED = 1;
const REPEAT_INTERVAL = 100;

interface AnimatedRouteProps {
  routePoints: LatLngLiteral[];
  routeColor: string;
  highlightedRouteColor?: string;
  shouldAnimate?: boolean;
  animationSpeed?: number;
  zIndex?: number;
  isHighlighted?: boolean;
  onRouteClick?: (e) => void;
}

export const ROUTE_HOVER_STROKE_WIDTH = 20;

export default function AnimatedRoute({
  routePoints,
  routeColor,
  highlightedRouteColor,
  shouldAnimate = true,
  animationSpeed = ANIM_BASE_SPEED,
  zIndex = 0,
  isHighlighted = false,
  onRouteClick,
}: AnimatedRouteProps) {
  const mapTestMode = useContext(MapTestModeContext);
  const [isHovered, setIsHovered] = useState(false);
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    const updateOffsetInterval =
      shouldAnimate && !mapTestMode
        ? setInterval(() => {
            setOffset(
              (offsetValue) =>
                (offsetValue + animationSpeed) %
                (REPEAT_INTERVAL - animationSpeed)
            );
          }, 20)
        : undefined;
    return () => {
      if (updateOffsetInterval) {
        clearInterval(updateOffsetInterval);
      }
    };
  }, [animationSpeed, shouldAnimate, mapTestMode]);
  return (
    <>
      <Polyline
        options={{
          clickable: onRouteClick !== undefined,
          strokeColor: highlightedRouteColor ?? routeColor,
          strokeWeight: ROUTE_HOVER_STROKE_WIDTH,
          strokeOpacity: isHovered || isHighlighted ? 0.3 : 0,
          zIndex,
        }}
        path={routePoints}
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
        onClick={(e) => {
          setIsHovered(false);
          runIfFn(onRouteClick, e);
        }}
      />
      <Polyline
        options={{
          clickable: false,
          strokeColor: routeColor,
          strokeWeight: 3,
          strokeOpacity: 1,
          icons: !shouldAnimate
            ? undefined
            : [
                {
                  icon: {
                    path: google.maps.SymbolPath.CIRCLE,
                    scale: 4,
                    strokeColor: routeColor,
                    strokeOpacity: 0.3,
                    strokeWeight: 6,
                    fillColor: routeColor,
                    fillOpacity: 1,
                  },
                  offset: `${offset}px`,
                  repeat: `${REPEAT_INTERVAL}px`,
                },
              ],
          zIndex,
        }}
        path={routePoints}
      />
    </>
  );
}

export function shouldRouteAnimated(mapZoom: number | undefined): boolean {
  return (mapZoom ?? 0) > ANIMATION_ZOOM_THRESHOLD;
}

interface RoutesNearbyProps<T> {
  selectedRoute: T;
  routes: T[];
  point: google.maps.LatLng | google.maps.LatLngLiteral;
  mapZoom: number | undefined;
}
export function routesNearby<T extends Route | RouteSummary>({
  selectedRoute,
  routes,
  point,
  mapZoom,
}: RoutesNearbyProps<T>): T[] {
  const clickProximityPrecision =
    ROUTE_HOVER_STROKE_WIDTH / 2 / 2 ** ((mapZoom || 1) - 1);
  const routesNearBy = [
    selectedRoute,
    ...routes.filter(
      (testingRoute) =>
        testingRoute !== selectedRoute &&
        testingRoute.wkt &&
        point &&
        google.maps.geometry.poly.isLocationOnEdge(
          point,
          new google.maps.Polyline({
            path: parseWkt(testingRoute.wkt),
          }),
          clickProximityPrecision
        )
    ),
  ];
  return routesNearBy;
}
