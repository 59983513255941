/* eslint-disable no-param-reassign */
import { FunctionComponent, SVGProps } from 'react';
import Highcharts, { AxisPlotBandsOptions, SeriesOptions } from 'highcharts';
import type { DataType } from '@webapp/bff';
import { errorReport } from './errors';
import { Site } from '../types/site';
import { InsightType } from '../types/panelData';
import ClockIcon from '../svg/clock.svg?react';
import TrafficIcon from '../svg/traffic.svg?react';
import SpeedometerIcon from '../svg/speedometer.svg?react';
import imagePathControlKnob from '../svg/control-knob.svg?url';
import imagePathControlPlus from '../svg/control-plus.svg?url';

import {
  getAnomalyScoreColor,
  getAnomalyScorePercentage,
} from '../data/useOrgRoutes';

export type LegacyInsightType =
  | 'speed'
  | 'journeyTime'
  | 'timeDelay'
  | 'queueLength'
  | 'siteImpactDelay'
  | 'dust'
  | 'noise'
  | 'rain'
  | 'count'
  | 'heavyCount';

export interface InsightPerformanceConfig extends InsightConfig {
  formatScoreValue?: (value: number) => string;
  colorForScoreValue?: (value: number) => string;
}

export interface InsightConfig {
  iconSVG?: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
  title: string;
  formatValue: (value: number) => string;
  kpi?: {
    label: string;
    value: number;
  };
}

export type ChartType = 'line' | 'column' | 'scatter';

export interface InsightChartConfig {
  chartType: ChartType;
  title: string;
  dataAxisTitle: string;
  dataAxisInterval?: number;
  dataAxisPlotBands?: AxisPlotBandsOptions[];
  zoomingType?: 'x' | 'y' | 'xy';
  formatValue: (value: number) => string;
  kpi?: {
    label: string;
    value: number;
  };
  disableCrosshair?: boolean;
  disableTooltip?: boolean;
}

export interface InsightChartSeries extends SeriesOptions {
  data: [number, number | undefined, number | undefined][];
  color: string;
  keys: string[];
  showInLegend?: boolean;
  visible?: boolean;
  custom?: Record<string, any>;
  animation?: boolean;
  dashStyle?: string;
}

export function getInsightConfigFromType(
  insightType: InsightType | LegacyInsightType,
  siteData: Partial<
    Pick<Site, 'siteUnit' | 'delayKPI' | 'queueKPI' | 'speedKPI'>
  >,
  formatMessage
): InsightConfig {
  switch (insightType) {
    case 'TIME_DELAY':
    case 'timeDelay':
      return {
        title: formatMessage({
          defaultMessage: 'Time delay',
          id: 'C8skLr',
          description: 'Time delay chart title',
        }),
        formatValue: formatSeconds,
        kpi: !siteData.delayKPI
          ? undefined
          : {
              label: formatMessage({
                defaultMessage: 'Delay KPI',
                id: 'bk5ncy',
                description: 'Time delay chart kpi label',
              }),
              value: siteData.delayKPI,
            },
      };
    case 'DUST':
    case 'dust':
      return {
        title: formatMessage({
          defaultMessage: 'Environment',
          id: 'HKxYlr',
          description: 'Environment chart title',
        }),
        formatValue: (value) => `${Math.round(value)}ug/m3`,
        kpi: !siteData.delayKPI
          ? undefined
          : {
              label: formatMessage({
                defaultMessage: 'Delay KPI',
                id: 'bk5ncy',
                description: 'Time delay chart kpi label',
              }),
              value: siteData.delayKPI,
            },
      };
    case 'JOURNEY_TIME':
    case 'journeyTime':
      return {
        iconSVG: ClockIcon,
        title: formatMessage({
          defaultMessage: 'Journey Time',
          id: 'NxnG9Q',
          description: 'Journey Time chart title',
        }),
        formatValue: (value) => formatSeconds(value),
      };
    case 'NOISE':
    case 'noise':
      return {
        title: formatMessage({
          defaultMessage: 'Noise',
          id: 'kWonJZ',
          description: 'Noise chart title',
        }),
        formatValue: (value) => `${Math.round(value)}dB`,
      };
    case 'QUEUE_LENGTH':
    case 'queueLength':
      return {
        iconSVG: TrafficIcon,
        title: formatMessage({
          defaultMessage: 'Queue length',
          id: '9u2d7K',
          description: 'Queue chart title',
        }),
        formatValue: (value) =>
          `${
            siteData.siteUnit === 'IMPERIAL'
              ? (value * 0.000621371).toFixed(2)
              : value
          }${siteData.siteUnit === 'IMPERIAL' ? 'mi' : 'm'}`,
        kpi: !siteData.queueKPI
          ? undefined
          : {
              label: formatMessage({
                defaultMessage: 'Queue KPI',
                id: 'quOsC1',
                description: 'Queue chart kpi label',
              }),
              value: siteData.queueKPI,
            },
      };
    case 'RAIN':
    case 'rain':
      return {
        title: formatMessage({
          defaultMessage: 'Rain',
          id: '6RJJbG',
          description: 'Rain chart title',
        }),
        formatValue: (value: number) => `${value}mm`,
      };
    case 'SITE_IMPACT_DELAY':
    case 'siteImpactDelay':
      return {
        title: formatMessage({
          defaultMessage: 'Site Impact',
          id: 'LT1P93',
          description: 'Site impact chart title',
        }),
        formatValue: (value) => formatSeconds(value),
        kpi: !siteData.delayKPI
          ? undefined
          : {
              label: formatMessage({
                defaultMessage: 'Delay KPI',
                id: 'QjOdRj',
                description: 'Site impact delay chart kpi label',
              }),
              value: siteData.delayKPI,
            },
      };
    case 'SPEED':
    case 'speed':
      return {
        iconSVG: SpeedometerIcon,
        title: formatMessage({
          defaultMessage: 'Average Speed',
          id: 'RSw2Pc',
          description: 'Speed chart label',
        }),
        formatValue: (value: number) =>
          `${Math.round(
            siteData.siteUnit === 'IMPERIAL' ? 0.621371 * value : value
          )}${siteData.siteUnit === 'IMPERIAL' ? 'mph' : 'kmph'}`,
        kpi: !siteData.speedKPI
          ? undefined
          : {
              label: formatMessage({
                defaultMessage: 'Speed KPI',
                id: '7krvwe',
                description: 'Speed chart kpi label',
              }),
              value: siteData.speedKPI,
            },
      };
    case 'VEHICLE_COUNT':
    case 'count':
      return {
        title: formatMessage({
          defaultMessage: 'Vehicle Count',
          id: '4+cMZa',
          description: 'Vehicle count chart title',
        }),
        formatValue: (value) => `${Math.round(value)}`,
      };
    case 'COMPLETED_TRIP_TIME':
      return {
        title: formatMessage({
          defaultMessage: 'Completed trip time',
          id: 'A0dRjz',
          description: 'Completed trip time chart label',
        }),
        formatValue: (value) => formatSeconds(value),
      };
    case 'TRIP_COUNT':
      return {
        title: formatMessage({
          defaultMessage: 'Trip count',
          id: 'J+v/Vk',
          description: 'Trip count time chart label',
        }),
        formatValue: (value) => `${Math.round(value)}`,
      };
    case 'DWELL_TIME':
      return {
        title: formatMessage({
          defaultMessage: 'Dwell time',
          id: 'DaYDh9',
          description: 'Dwell time chart label',
        }),
        formatValue: (value) => formatSeconds(value),
      };
    default:
      errorReport.handled(
        new Error(`Failed to parse unsupported insight type: ${insightType}`)
      );
      return {
        title: insightType,
        formatValue: (value) => value.toString(),
      };
  }
}

export function getChartConfigFromChartType(
  insightType: InsightType,
  siteData: Partial<
    Pick<Site, 'siteUnit' | 'delayKPI' | 'queueKPI' | 'speedKPI'>
  >,
  formatMessage
): InsightChartConfig {
  const insightConfig = getInsightConfigFromType(
    insightType,
    siteData,
    formatMessage
  );
  switch (insightType) {
    case 'TIME_DELAY':
      return {
        ...insightConfig,
        chartType: 'line',
        dataAxisTitle: formatMessage({
          defaultMessage: 'Delay (in minutes)',
          id: 'AWKvAr',
          description: 'Time delay yaxis label',
        }),
        dataAxisInterval: 60,
        zoomingType: 'x',
      };
    case 'DUST':
      return {
        ...insightConfig,
        chartType: 'line',
        dataAxisTitle: formatMessage({
          defaultMessage: 'Dust (in ug/m3)',
          id: 'e9eMA6',
          description: 'Environment yaxis label',
        }),
        zoomingType: 'x',
      };
    case 'JOURNEY_TIME':
      return {
        ...insightConfig,
        chartType: 'line',
        dataAxisTitle: formatMessage({
          defaultMessage: 'Travel time (in minutes)',
          id: '5bejev',
          description: 'Travel time yaxis label',
        }),
        dataAxisInterval: 60,
        zoomingType: 'x',
      };
    case 'NOISE':
      return {
        ...insightConfig,
        chartType: 'line',
        dataAxisTitle: formatMessage({
          defaultMessage: 'Noise (in dB)',
          id: 'd9GwKQ',
          description: 'Noise yaxis label',
        }),
        zoomingType: 'x',
      };
    case 'QUEUE_LENGTH':
      return {
        ...insightConfig,
        chartType: 'column',
        dataAxisTitle: formatMessage(
          {
            defaultMessage: 'Queue length (in {queueLengthUnit})',
            id: 'W0ypkq',
            description: 'Queue yaxis label',
          },
          {
            queueLengthUnit:
              siteData.siteUnit === 'IMPERIAL' ? 'miles' : 'meters',
          }
        ),
        zoomingType: 'x',
      };
    case 'RAIN':
      return {
        ...insightConfig,
        chartType: 'line',
        dataAxisTitle: formatMessage({
          defaultMessage: 'Rain (in mm)',
          id: 'G3gDKu',
          description: 'Rain yaxis label',
        }),
        zoomingType: 'x',
      };
    case 'SITE_IMPACT_DELAY':
      return {
        ...insightConfig,
        chartType: 'line',
        dataAxisTitle: formatMessage({
          defaultMessage: 'Delay time caused by site (in minutes)',
          id: '3rS9sV',
          description: 'Site impact yaxis label',
        }),
        dataAxisInterval: 60,
        zoomingType: 'x',
      };
    case 'SPEED':
      return {
        ...insightConfig,
        chartType: 'line',
        dataAxisTitle: formatMessage(
          {
            defaultMessage: 'Average Speed (in {speedUnit})',
            id: 'B4x8nM',
            description: 'Speed yaxis label',
          },
          {
            speedUnit: siteData.siteUnit === 'IMPERIAL' ? 'mph' : 'kmph',
          }
        ),
        zoomingType: 'x',
      };
    case 'VEHICLE_COUNT':
      return {
        ...insightConfig,
        chartType: 'column',
        dataAxisTitle: formatMessage({
          defaultMessage: 'Vehicle Count',
          id: 'FMAO6Z',
          description: 'Vehicle count yaxis label',
        }),
        zoomingType: 'x',
      };
    case 'TRIP_COUNT':
      return {
        ...insightConfig,
        chartType: 'column',
        dataAxisTitle: formatMessage({
          defaultMessage: 'Trip Count',
          id: 'D9LFiO',
          description: 'Trip count yaxis label',
        }),
        zoomingType: 'x',
      };
    case 'COMPLETED_TRIP_TIME':
      return {
        ...insightConfig,
        chartType: 'scatter',
        dataAxisTitle: formatMessage({
          defaultMessage: 'Trip time (in minutes)',
          id: '/OQORd',
          description: 'Completed trip time yaxis label',
        }),
        dataAxisInterval: 60,
        zoomingType: 'xy',
      };
    case 'DWELL_TIME':
      return {
        ...insightConfig,
        chartType: 'scatter',
        dataAxisTitle: formatMessage({
          defaultMessage: 'Dwell time (in minutes)',
          id: 'Bqyap+',
          description: 'Dwell time yaxis label',
        }),
        dataAxisInterval: 60,
        zoomingType: 'xy',
      };
    default:
      errorReport.handled(
        new Error(`Failed to parse unsupported chart type: ${insightType}`)
      );
      return {
        ...insightConfig,
        chartType: 'line',
        dataAxisTitle: formatMessage({
          defaultMessage: 'Unknown data type',
          id: 'yzwtpG',
          description: 'Unknown chart yaxis label',
        }),
      };
  }
}

export const getDataTypeFromSectionName = (sectionName: string): DataType => {
  switch (sectionName?.toLowerCase()) {
    case 'average speed':
    case 'speed':
      return 'SPEED';
    case 'site impact':
      return 'SITE_IMPACT_DELAY';
    case 'vehicle count':
      return 'VEHICLE_COUNT';
    case 'delay profile':
    case 'time delay':
      return 'TIME_DELAY';
    case 'journey time':
      return 'JOURNEY_TIME';
    case 'travel time':
      return 'JOURNEY_TIME';
    case 'queue length':
      return 'QUEUE_LENGTH';
    case 'noise':
      return 'NOISE';
    case 'rain':
      return 'RAIN';
    case 'environment':
      return 'ENVIRONMENT';
    default:
      return 'JOURNEY_TIME';
  }
};

export function getInsightPerformanceConfigFromType(
  insightType: InsightType | LegacyInsightType,
  siteData: Partial<Site>,
  formatMessage
): InsightPerformanceConfig {
  const insightConfig = getInsightConfigFromType(
    insightType,
    siteData,
    formatMessage
  );
  switch (insightType) {
    case 'TIME_DELAY':
    case 'DUST':
    case 'NOISE':
    case 'QUEUE_LENGTH':
    case 'RAIN':
    case 'SITE_IMPACT_DELAY':
    case 'SPEED':
    case 'VEHICLE_COUNT':
    case 'JOURNEY_TIME':
      return {
        ...insightConfig,
        formatScoreValue: (value: number) => getAnomalyScorePercentage(value),
        colorForScoreValue: (value: number) => getAnomalyScoreColor(value),
      };
    default:
      errorReport.handled(
        new Error(`Failed to parse unsupported insight type: ${insightType}`)
      );
      return {
        ...insightConfig,
      };
  }
}

export function formatSeconds(timeInSecs) {
  if (!timeInSecs && timeInSecs !== 0) {
    return '';
  }
  const hour =
    timeInSecs > 0
      ? Math.floor(timeInSecs / 3600)
      : Math.ceil(timeInSecs / 3600);
  const min =
    timeInSecs > 0
      ? Math.floor(timeInSecs / 60) % 60
      : Math.ceil(timeInSecs / 60) % 60;
  const sec = timeInSecs % 60;

  const rst =
    (hour !== 0 ? `${hour}h ` : '') +
    (min !== 0 ? `${min}m ` : '') +
    (sec !== 0 ? `${sec}s` : '');
  return rst === '' ? '0s' : rst;
}

// Highcharts Control Mode actions
const knobDistance = 24;

export const findPlotLineOrBandById = (id, xAxis) =>
  xAxis.plotLinesAndBands.find((p) => p.id === id);

const addPlotLine = (id, xAxis, xPos, color) =>
  xAxis.addPlotLine({
    value: xPos,
    width: 1,
    color,
    id,
    dashStyle: 'ShortDash',
    zIndex: 2,
  });

const addPlotLineByPixel = (id, xAxis, xPixel, color, chart) => {
  const plotLineValue = xAxis.toValue(xPixel - chart.plotLeft, true);
  addPlotLine(id, xAxis, plotLineValue, color);
};

const addPlotBandByPixels = (id, fromPixel, toPixel, xAxis, color, chart) => {
  const controlPlotBandStart = xAxis.toValue(fromPixel - chart.plotLeft, true);
  const controlPlotBandEnd = xAxis.toValue(toPixel - chart.plotLeft, true);
  return xAxis.addPlotBand({
    from: controlPlotBandStart,
    to: controlPlotBandEnd,
    color,
    id,
  });
};

const updateControlPlotBand = (id, from, to, chart) => {
  chart.xAxis[0].removePlotBand(id);
  chart.xAxis[0].addPlotBand({
    id: 'controlPlotBand',
    from,
    to,
    color: '#B9F6CA',
  });
};

const performUpdate = (
  fromValue,
  toValue,
  chart,
  callBack: (controlPoint: ControlPoint) => void
) => {
  if (fromValue > toValue) {
    callBack({
      start: {
        time: toValue,
        xPos: chart.xAxis[0].toPixels(toValue, false),
      },
      end: {
        time: fromValue,
        xPos: chart.xAxis[0].toPixels(fromValue, false),
      },
    });
  } else {
    callBack({
      start: {
        time: fromValue,
        xPos: chart.xAxis[0].toPixels(fromValue, false),
      },
      end: {
        time: toValue,
        xPos: chart.xAxis[0].toPixels(toValue, false),
      },
    });
  }
  updateControlPlotBand('controlPlotBand', fromValue, toValue, chart);
};

const chartMouseMove =
  (
    chart: InsightHighchartsType,
    callBack: (controlPoint: ControlPoint) => void
  ) =>
  (e) => {
    if (chart.mainKnob && chart.mainKnob.isDragging) {
      if (
        e.chartX >= chart.plotLeft &&
        e.chartX <= chart.plotLeft + chart.plotWidth
      ) {
        chart.mainKnob.attr({
          x: e.chartX - 16,
        });

        chart.xAxis[0].removePlotBand('mainPlotLine');
        const newXValue = chart.xAxis[0].toValue(
          e.chartX - chart.plotLeft,
          true
        );
        callBack({
          start: {
            time: newXValue,
            xPos: chart.xAxis[0].toPixels(newXValue, false),
          },
        });
        addPlotLine('mainPlotLine', chart.xAxis[0], newXValue, '#007C49');
        if (chart.leftPlus) {
          chart.leftPlus.attr({
            x: e.chartX - (knobDistance + 24),
          });
        }
        if (chart.rightPlus) {
          chart.rightPlus.attr({
            x: e.chartX + knobDistance,
          });
        }
      }
    }
    if (chart.mainKnobA && chart.mainKnobA.isDragging) {
      if (
        e.chartX >= chart.plotLeft &&
        e.chartX <= chart.plotLeft + chart.plotWidth
      ) {
        const controlPlotBand = findPlotLineOrBandById(
          'controlPlotBand',
          chart.xAxis[0]
        );
        if (controlPlotBand) {
          const newFromValue = chart.xAxis[0].toValue(
            e.chartX - chart.plotLeft,
            true
          );
          const originalToValue = controlPlotBand.options.to;
          performUpdate(newFromValue, originalToValue, chart, callBack);
          chart.xAxis[0].removePlotBand('plotLineA');
          addPlotLine('plotLineA', chart.xAxis[0], newFromValue, '#007C49');
          chart.mainKnobA.attr({
            x: e.chartX - 16,
          });
        }
      }
    }

    if (chart.mainKnobB && chart.mainKnobB.isDragging) {
      if (
        e.chartX >= chart.plotLeft &&
        e.chartX <= chart.plotLeft + chart.plotWidth
      ) {
        const controlPlotBand = findPlotLineOrBandById(
          'controlPlotBand',
          chart.xAxis[0]
        );
        if (controlPlotBand) {
          const originalFromValue = controlPlotBand.options.from;
          const newToValue = chart.xAxis[0].toValue(
            e.chartX - chart.plotLeft,
            true
          );
          performUpdate(originalFromValue, newToValue, chart, callBack);
          chart.xAxis[0].removePlotBand('plotLineB');
          addPlotLine('plotLineB', chart.xAxis[0], newToValue, '#007C49');
          chart.mainKnobB.attr({
            x: e.chartX - 16,
          });
        }
      }
    }
  };

const chartMouseUp = (chart: InsightHighchartsType) => () => {
  if (chart.mainKnob) {
    chart.mainKnob.isDragging = false;
  }
  if (chart.mainKnobA) {
    chart.mainKnobA.isDragging = false;
  }
  if (chart.mainKnobB) {
    chart.mainKnobB.isDragging = false;
  }
};

const addControlBandKnobs = (
  chart,
  leftKnobPixelX,
  rightKnobPixelX,
  pixelY
) => {
  chart.mainKnobA = chart.renderer
    .image(imagePathControlKnob, leftKnobPixelX - 16, pixelY, 32, 32)
    .on('mousedown', () => {
      if (chart.mainKnobA) {
        chart.mainKnobA.isDragging = true;
      }
    })
    .on('touchstart', () => {
      if (chart.mainKnobA) {
        chart.mainKnobA.isDragging = true;
      }
    })
    .attr({ zIndex: 7, cursor: 'pointer' })
    .add();
  chart.mainKnobB = chart.renderer
    .image(imagePathControlKnob, rightKnobPixelX - 16, pixelY, 32, 32)
    .on('mousedown', () => {
      if (chart.mainKnobB) {
        chart.mainKnobB.isDragging = true;
      }
    })
    .on('touchstart', () => {
      if (chart.mainKnobB) {
        chart.mainKnobB.isDragging = true;
      }
    })
    .attr({ zIndex: 7, cursor: 'pointer' })
    .add();
};

const updateControlKnob = (knob, plotLine, xAxis) => {
  const xValeOnAxis = plotLine.options.value;
  const mainKnobBPixelX = xAxis.toPixels(xValeOnAxis, false);
  knob.attr({ x: mainKnobBPixelX - 16 });
};

const destroyFirstPhaseControl = (chart: InsightHighchartsType) => {
  if (chart) {
    if (chart.mainKnob) {
      chart.xAxis[0].removePlotBand('mainPlotLine');
      chart.mainKnob.destroy();
      chart.mainKnob = undefined;
    }
    if (chart.leftPlus) {
      chart.leftPlus.destroy();
      chart.leftPlus = undefined;
    }
    if (chart.rightPlus) {
      chart.rightPlus.destroy();
      chart.rightPlus = undefined;
    }
  }
};

const destroySecondPhaseControl = (chart: InsightHighchartsType) => {
  if (chart) {
    if (chart.mainKnobA) {
      chart.mainKnobA.destroy();
      chart.mainKnobA = undefined;
    }
    if (chart.mainKnobB) {
      chart.mainKnobB.destroy();
      chart.mainKnobB = undefined;
    }
    chart.xAxis[0].removePlotBand('controlPlotBand');
    chart.xAxis[0].removePlotBand('plotLineA');
    chart.xAxis[0].removePlotBand('plotLineB');
  }
};

const createSecondPhaseKnobs = (
  leftKnobPixelX,
  rightKnobPixelX,
  mainPixelY,
  chart
) => {
  addPlotBandByPixels(
    'controlPlotBand',
    leftKnobPixelX,
    rightKnobPixelX,
    chart.xAxis[0],
    '#B9F6CA',
    chart
  );
  addControlBandKnobs(chart, leftKnobPixelX, rightKnobPixelX, mainPixelY);
  addPlotLineByPixel(
    'plotLineA',
    chart.xAxis[0],
    leftKnobPixelX,
    '#007C49',
    chart
  );
  addPlotLineByPixel(
    'plotLineB',
    chart.xAxis[0],
    rightKnobPixelX,
    '#007C49',
    chart
  );
};

export type InsightHighchartsType = Highcharts.Chart & {
  touchMove?: (e) => void;
  touchEnd?: (e) => void;
  touchCancel?: (e) => void;
  mouseMove?: (e) => void;
  mouseUp?: (e) => void;
  mouseLeave?: (e) => void;
  mainKnob?: Highcharts.SVGElement & { isDragging?: boolean };
  leftPlus?: Highcharts.SVGElement;
  rightPlus?: Highcharts.SVGElement;
  mainKnobA?: Highcharts.SVGElement & { isDragging?: boolean };
  mainKnobB?: Highcharts.SVGElement & { isDragging?: boolean };
  popover?: React.RefObject<HTMLDivElement>;
  xPosDiff?: number;
};

type ChartPoint = {
  time: number;
  xPos: number;
};

export type ControlPoint = {
  start: ChartPoint;
  end?: ChartPoint;
};

export const goToSecondPhaseOnTheRight = (
  updateControlStatus: (controlPoint: ControlPoint) => void,
  chart?: InsightHighchartsType
) => {
  if (chart) {
    if (chart.rightPlus) {
      const mainPlotLine = findPlotLineOrBandById(
        'mainPlotLine',
        chart.xAxis[0]
      );
      if (mainPlotLine) {
        const xValeOnAxis = mainPlotLine.options.value;
        const leftKnobPixelX = chart.xAxis[0].toPixels(xValeOnAxis, false);
        const rightKnobPixelX = leftKnobPixelX + 32;
        const rightKnobValueX = chart.xAxis[0].toValue(
          rightKnobPixelX - chart.plotLeft,
          true
        );
        updateControlStatus({
          start: { time: xValeOnAxis, xPos: leftKnobPixelX },
          end: { time: rightKnobValueX, xPos: rightKnobPixelX },
        });
        createSecondPhaseKnobs(
          leftKnobPixelX,
          rightKnobPixelX,
          chart.yAxis[0].toPixels(chart.yAxis[0].max!, false),
          chart
        );
      }
      destroyFirstPhaseControl(chart);
    }
  }
};

const createFirstPhaseControl = (
  mainPointX,
  chart: InsightHighchartsType,
  updateControlStatus: (controlPoint: ControlPoint) => void
) => {
  destroySecondPhaseControl(chart);
  const { renderer } = chart;
  const mainPixelX = chart.xAxis[0].toPixels(mainPointX, false);
  const mainPixelY = chart.yAxis[0].toPixels(chart.yAxis[0].max!, false);
  addPlotLine('mainPlotLine', chart.xAxis[0], mainPointX, '#007C49');
  updateControlStatus({
    start: { time: mainPointX, xPos: mainPixelX },
  });
  chart.mainKnob = renderer
    .image(imagePathControlKnob, mainPixelX - 16, mainPixelY, 32, 32)
    .on('mousedown', () => {
      if (chart.mainKnob) {
        chart.mainKnob.isDragging = true;
      }
    })
    .on('touchstart', () => {
      if (chart.mainKnob) {
        chart.mainKnob.isDragging = true;
      }
    })
    .attr({ zIndex: 7, cursor: 'pointer' })
    .add();
  chart.leftPlus = renderer
    .image(
      imagePathControlPlus,
      mainPixelX - (knobDistance + 24),
      mainPixelY + 4,
      24,
      24
    )
    .on('mousedown', () => {
      if (chart.leftPlus) {
        const mainPlotLine = findPlotLineOrBandById(
          'mainPlotLine',
          chart.xAxis[0]
        );
        if (mainPlotLine) {
          const xValeOnAxis = mainPlotLine.options.value;
          const rightKnobPixelX = chart.xAxis[0].toPixels(xValeOnAxis, false);
          const leftKnobPixelX = rightKnobPixelX - 32;
          const leftKnobValueX = chart.xAxis[0].toValue(
            leftKnobPixelX - chart.plotLeft,
            true
          );
          updateControlStatus({
            start: { time: leftKnobValueX, xPos: leftKnobPixelX },
            end: { time: xValeOnAxis, xPos: rightKnobPixelX },
          });
          createSecondPhaseKnobs(
            leftKnobPixelX,
            rightKnobPixelX,
            mainPixelY,
            chart
          );
        }
        destroyFirstPhaseControl(chart);
      }
    })
    .attr({ zIndex: 7, cursor: 'pointer' })
    .add();
  chart.rightPlus = renderer
    .image(
      imagePathControlPlus,
      mainPixelX + knobDistance,
      mainPixelY + 4,
      24,
      24
    )
    .on('mousedown', () => {
      goToSecondPhaseOnTheRight(updateControlStatus, chart);
    })
    .attr({ zIndex: 7, cursor: 'pointer' })
    .add();
};

export const backToFirstPhaseControl = (
  updateControlStatus: (controlPoint: ControlPoint) => void,
  chart?: InsightHighchartsType
) => {
  if (chart) {
    const plotLineA = findPlotLineOrBandById('plotLineA', chart.xAxis[0]);
    const plotLineB = findPlotLineOrBandById('plotLineB', chart.xAxis[0]);
    if (plotLineA && plotLineB) {
      let valueX;
      if (plotLineA.options.value < plotLineB.options.value) {
        valueX = plotLineA.options.value;
      } else {
        valueX = plotLineB.options.value;
      }
      createFirstPhaseControl(valueX, chart, updateControlStatus);
    }
  }
};

export const updateChartWhenStartDateChange = (
  updateControlStatus: (controlPoint: ControlPoint) => void,
  startDateEpoch: number | undefined,
  chart: InsightHighchartsType | undefined
) => {
  if (chart) {
    if (chart.mainKnob && startDateEpoch) {
      const mainKnobPixelX = chart.xAxis[0].toPixels(
        startDateEpoch * 1000,
        false
      );
      chart.xAxis[0].removePlotBand('mainPlotLine');
      addPlotLine(
        'mainPlotLine',
        chart.xAxis[0],
        startDateEpoch * 1000,
        '#007C49'
      );
      chart.mainKnob.attr({ x: mainKnobPixelX - 16 });
      if (chart.leftPlus) {
        chart.leftPlus.attr({
          x: mainKnobPixelX - (knobDistance + 24),
        });
      }
      if (chart.rightPlus) {
        chart.rightPlus.attr({
          x: mainKnobPixelX + knobDistance,
        });
      }
      updateControlStatus({
        start: { time: startDateEpoch * 1000, xPos: mainKnobPixelX },
      });
    }

    if (chart.mainKnobA && chart.mainKnobB && startDateEpoch) {
      const plotLineA = findPlotLineOrBandById('plotLineA', chart.xAxis[0]);
      const plotLineB = findPlotLineOrBandById('plotLineB', chart.xAxis[0]);
      if (plotLineA && plotLineB) {
        const controlPlotBand = findPlotLineOrBandById(
          'controlPlotBand',
          chart.xAxis[0]
        );
        const knobPixelX = chart.xAxis[0].toPixels(
          startDateEpoch * 1000,
          false
        );
        if (plotLineA.options.value < plotLineB.options.value) {
          const originalToValue = controlPlotBand.options.to;
          chart.xAxis[0].removePlotBand('plotLineA');
          addPlotLine(
            'plotLineA',
            chart.xAxis[0],
            startDateEpoch * 1000,
            '#007C49'
          );
          chart.mainKnobA.attr({
            x: knobPixelX - 16,
          });
          performUpdate(
            startDateEpoch * 1000,
            originalToValue,
            chart,
            updateControlStatus
          );
        } else {
          const originalFromValue = controlPlotBand.options.from;
          chart.xAxis[0].removePlotBand('plotLineB');
          addPlotLine(
            'plotLineB',
            chart.xAxis[0],
            startDateEpoch * 1000,
            '#007C49'
          );
          chart.mainKnobB.attr({
            x: knobPixelX - 16,
          });
          performUpdate(
            originalFromValue,
            startDateEpoch * 1000,
            chart,
            updateControlStatus
          );
        }
      }
    }
  }
};

export const updateChartWhenEndDateChange = (
  updateControlStatus: (controlPoint: ControlPoint) => void,
  endDateEpoch: number | undefined,
  chart: InsightHighchartsType | undefined
) => {
  if (chart) {
    if (chart.mainKnobA && chart.mainKnobB && endDateEpoch) {
      const controlPlotBand = findPlotLineOrBandById(
        'controlPlotBand',
        chart.xAxis[0]
      );
      const plotLineA = findPlotLineOrBandById('plotLineA', chart.xAxis[0]);
      const plotLineB = findPlotLineOrBandById('plotLineB', chart.xAxis[0]);
      if (plotLineA && plotLineB) {
        const knobPixelX = chart.xAxis[0].toPixels(endDateEpoch * 1000, false);
        if (plotLineA.options.value < plotLineB.options.value) {
          chart.xAxis[0].removePlotBand('plotLineB');
          addPlotLine(
            'plotLineB',
            chart.xAxis[0],
            endDateEpoch * 1000,
            '#007C49'
          );
          chart.mainKnobB.attr({
            x: knobPixelX - 16,
          });
          performUpdate(
            controlPlotBand.options.from,
            endDateEpoch * 1000,
            chart,
            updateControlStatus
          );
        } else {
          chart.xAxis[0].removePlotBand('plotLineA');
          addPlotLine(
            'plotLineA',
            chart.xAxis[0],
            endDateEpoch * 1000,
            '#007C49'
          );
          chart.mainKnobA.attr({
            x: knobPixelX - 16,
          });
          performUpdate(
            endDateEpoch * 1000,
            controlPlotBand.options.to,
            chart,
            updateControlStatus
          );
        }
      }
    }
  }
};

export type UpdateChartCallbacks = {
  backToFirstPhase: () => void;
  goToSecondPhase: () => void;
  updateChartStartDate: (startDateEpoch: number | undefined) => void;
  updateChartEndDate: (endDateEpoch: number | undefined) => void;
};

export const startControlMode = (
  chart: InsightHighchartsType | undefined,
  updateControlStatus: (controlPoint: ControlPoint) => void
) => {
  if (chart && chart.xAxis) {
    const xAxis = chart.xAxis[0];
    const mainCenterPointX = (xAxis.min! + xAxis.max!) / 2;
    createFirstPhaseControl(mainCenterPointX, chart, updateControlStatus);
    chart.touchMove = chartMouseMove(chart, updateControlStatus);
    chart.container.addEventListener('touchmove', chart.touchMove);
    chart.mouseMove = chartMouseMove(chart, updateControlStatus);
    chart.container.addEventListener('mousemove', chart.mouseMove);
    chart.touchEnd = chartMouseUp(chart);
    chart.container.addEventListener('touchend', chart.touchEnd);
    chart.mouseUp = chartMouseUp(chart);
    chart.container.addEventListener('mouseup', chart.mouseUp);
    chart.mouseLeave = chartMouseUp(chart);
    chart.container.addEventListener('mouseleave', chart.mouseLeave);
  }
};

export const cancelControlMode = (chart: InsightHighchartsType | undefined) => {
  if (chart) {
    if (chart.touchMove) {
      chart.container.removeEventListener('touchmove', chart.touchMove);
    }
    if (chart.mouseMove) {
      chart.container.removeEventListener('mousemove', chart.mouseMove);
    }
    if (chart.touchEnd) {
      chart.container.removeEventListener('touchend', chart.touchEnd);
    }
    if (chart.mouseUp) {
      chart.container.removeEventListener('mouseup', chart.mouseUp);
    }
    if (chart.mouseLeave) {
      chart.container.removeEventListener('mouseleave', chart.mouseLeave);
    }
    destroyFirstPhaseControl(chart);
    destroySecondPhaseControl(chart);
  }
};

export const positionPopover = (
  xPos: number,
  chart?: InsightHighchartsType
): number => {
  if (chart && chart.popover?.current) {
    const popoverWidth = chart.popover.current.clientWidth;
    const halfOfContentWidth = popoverWidth / 2;
    const maxLeft = chart.chartWidth - popoverWidth;
    let currentLeft = xPos - halfOfContentWidth;
    if (currentLeft < 0) {
      currentLeft = 0;
    }
    if (currentLeft > maxLeft) {
      currentLeft = maxLeft;
    }
    let xPosDiff = xPos - currentLeft - halfOfContentWidth;
    if (Number.isNaN(xPosDiff)) {
      xPosDiff = 0;
    }
    chart.xPosDiff = xPosDiff;
    return currentLeft;
  }
  return 0;
};

export const afterChartRender = (chart: InsightHighchartsType) => {
  const xAxis = chart.xAxis[0];
  const mainPlotLine = findPlotLineOrBandById('mainPlotLine', xAxis);
  if (mainPlotLine && chart.mainKnob) {
    const xValeOnAxis = mainPlotLine.options.value;
    const mainKnobPixelX = xAxis.toPixels(xValeOnAxis, false);
    if (chart.popover && chart.popover.current) {
      chart.popover.current.style.left = `${positionPopover(
        mainKnobPixelX,
        chart
      )}px`;
    }
    chart.mainKnob.attr({ x: mainKnobPixelX - 16 });
    if (chart.leftPlus) {
      chart.leftPlus.attr({
        x: mainKnobPixelX - (knobDistance + 24),
      });
    }
    if (chart.rightPlus) {
      chart.rightPlus.attr({
        x: mainKnobPixelX + knobDistance,
      });
    }
  }
  const plotLineA = findPlotLineOrBandById('plotLineA', xAxis);
  if (plotLineA && chart.mainKnobA) {
    updateControlKnob(chart.mainKnobA, plotLineA, xAxis);
  }

  const plotLineB = findPlotLineOrBandById('plotLineB', xAxis);
  if (plotLineB && chart.mainKnobB) {
    updateControlKnob(chart.mainKnobB, plotLineB, xAxis);
  }

  if (plotLineA && plotLineB) {
    let leftValue;
    if (plotLineA.options.value < plotLineB.options.value) {
      leftValue = xAxis.toPixels(plotLineA.options.value, false);
    } else {
      leftValue = xAxis.toPixels(plotLineB.options.value, false);
    }
    if (chart.popover && chart.popover.current) {
      chart.popover.current.style.left = `${positionPopover(
        leftValue,
        chart
      )}px`;
    }
  }
};
