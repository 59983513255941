import { defineMessage, FormattedMessage, MessageDescriptor } from 'react-intl';
import SelectionGroup from 'design-system/molecules/selection-group';
import { Box } from '@chakra-ui/react';
import { IncidentType } from 'design-system/atoms/custom-google-map/map-layer-incidents';
import { SiteView, SiteViewState } from '../../hooks/useSiteView';
import VmsIcon from '../../svg/VMS.svg?react';

const categoriesColors: Record<IncidentType, string> = {
  Accident: '#F6AD55',
  BrokenDownVehicle: '#F6E05E',
  DangerousConditions: '#FC8181',
  Flooding: '#38B2AC',
  Ice: '#90CDF4',
  Jam: '#FF0000',
  LaneClosed: '#F6AD55',
  PlannedEvent: '#F687B3',
  RoadClosed: '#FC8181',
  RoadWorks: '#ED8936',
  Weather: '#BEE3F8',
  Wind: '#BEE3F8',
  Other: '#F6E05E',
  Fog: '#D4DBE2',
  Rain: '#ACB5BF',
};

const categoryLabels: Record<IncidentType, MessageDescriptor> = {
  Accident: defineMessage({
    defaultMessage: 'Accident',
    id: 'jynko0',
  }),
  BrokenDownVehicle: defineMessage({
    defaultMessage: 'Brokendown Vehicle',
    id: 'wrSkJB',
  }),
  DangerousConditions: defineMessage({
    defaultMessage: 'Dangerous Conditions',
    id: 'lwN8YZ',
  }),
  Flooding: defineMessage({
    defaultMessage: 'Flooding',
    id: '0hiL9J',
  }),
  Ice: defineMessage({
    defaultMessage: 'Ice',
    id: 'FTnVdc',
  }),
  Jam: defineMessage({
    defaultMessage: 'Jam',
    id: '01bx/6',
  }),
  LaneClosed: defineMessage({
    defaultMessage: 'Lane Closed',
    id: 's0FFIe',
  }),
  PlannedEvent: defineMessage({
    defaultMessage: 'Planned Event',
    id: 'RU6aZp',
  }),
  RoadClosed: defineMessage({
    defaultMessage: 'Road Closed',
    id: 'mgIdtG',
  }),
  RoadWorks: defineMessage({
    defaultMessage: 'Road Works',
    id: 'RH46EZ',
  }),
  Wind: defineMessage({
    defaultMessage: 'Wind',
    id: 'ozzIX3',
  }),
  Fog: defineMessage({
    defaultMessage: 'Fog',
    id: 'LVwl69',
  }),
  Rain: defineMessage({
    defaultMessage: 'Rain',
    id: 'xtRgyp',
  }),
  Weather: defineMessage({
    defaultMessage: 'Weather',
    id: 'O2sqh9',
  }),
  Other: defineMessage({
    defaultMessage: 'Other',
    id: '/VnDMl',
  }),
};

interface VisibleIncidentsSelectorProps {
  categories?: string[];
  hiddenCategories: SiteViewState['hiddenIncidentCategories'];
  setHiddenCategories: SiteView['setHiddenIncidentCategories'];
}

export default function VisibleIncidentsSelector({
  categories = [],
  hiddenCategories,
  setHiddenCategories,
}: VisibleIncidentsSelectorProps) {
  const sortedCategories = [...categories].sort((catA, catB) =>
    catA.localeCompare(catB)
  );
  const selectedOptions = sortedCategories.filter(
    (cat) => !hiddenCategories.includes(cat)
  );

  const onSelectedOptionsChange = (newSelectedOptions: string[]) => {
    const newHiddenCats = sortedCategories.filter(
      (cat) => !newSelectedOptions.includes(cat)
    );
    setHiddenCategories(newHiddenCats);
  };

  return (
    <Box p={4}>
      <SelectionGroup
        title={
          <FormattedMessage
            defaultMessage="Incidents"
            id="JqP3Ep"
            description="Visible Incident categories selector title"
          />
        }
        titleIcon={<VmsIcon />}
        options={sortedCategories.map((cat) => ({
          active: true,
          id: cat,
          label: categoryLabels[cat],
          color: categoriesColors[cat],
        }))}
        selectedOptions={selectedOptions}
        onSelectedOptionsChange={onSelectedOptionsChange}
      />
    </Box>
  );
}
