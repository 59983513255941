import {
  Box,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tag,
  TagCloseButton,
  TagLabel,
  useDisclosure,
} from '@chakra-ui/react';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ControlPointIcon,
  DeleteIcon,
  TimerOnIcon,
} from '../../atoms/custom-icons';
import InfoTooltip from '../../atoms/info-tooltip';

export interface ContentNode {
  type: string;
  value: string;
  label?: string;
}
interface CaretPosition {
  nodeIndex: number;
  nodeCharIndex: number;
  charIndex: number;
}
function IsTagElement(node: ChildNode): node is HTMLElement {
  if (node.nodeType !== Node.ELEMENT_NODE) {
    return false;
  }
  return (
    (node as HTMLElement).hasAttribute('data-tag-type') &&
    (node as HTMLElement).hasAttribute('data-tag-value')
  );
}
interface VmsLineProps {
  value: ContentNode[];
  maxCharCount?: number;
  onChange: (value: ContentNode[]) => void;
  onAddNode: (
    type: string,
    selectedNode?: string
  ) => Promise<ContentNode | void>;
  onRemoveClick: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  autoFocus?: boolean;
  availableTypes?: { name: string; label: string }[];
  canAddTag?: boolean;
  isDisabled?: boolean;
}

const InputWithTags = forwardRef(InputWithTagsImpl);

const tagCharSize = 2;

export default function VmsLine({
  value,
  maxCharCount = 12,
  onChange,
  onAddNode,
  onRemoveClick,
  onFocus,
  onBlur,
  autoFocus,
  availableTypes,
  canAddTag,
  isDisabled,
}: VmsLineProps) {
  const [hasFocus, setHasFocus] = useState(false);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [insertPosition, setInsertPosition] = useState<CaretPosition | null>(
    null
  );
  const { formatMessage } = useIntl();
  const typesForNodeSelection = availableTypes ?? [
    {
      name: 'tag-journey-time',
      label: formatMessage({
        defaultMessage: 'Add journey time',
        id: 'Vpb/Tk',
      }),
    },
  ];
  const onTypeSelectHandler = async (type: string) => {
    const newItem = await onAddNode(type);
    if (newItem) {
      const itemAtPosition = insertPosition && value[insertPosition.nodeIndex];
      if (!itemAtPosition) {
        onChange([...value, newItem]);
        return;
      }
      if (itemAtPosition.type !== 'text') {
        const newValue = [...value];
        newValue.splice(insertPosition.nodeIndex, 0, newItem);
        onChange(newValue);
        return;
      }
      const preItem = {
        ...itemAtPosition,
        value: itemAtPosition.value.slice(0, insertPosition.nodeCharIndex),
      };
      const postItem = {
        ...itemAtPosition,
        value: itemAtPosition.value.slice(insertPosition.nodeCharIndex),
      };
      const newValue = [...value];
      newValue.splice(insertPosition.nodeIndex, 1, preItem, newItem, postItem);
      onChange(newValue);
    }
  };
  const addClickHandler = async (insertPositionOnAdd: CaretPosition | null) => {
    setInsertPosition(insertPositionOnAdd);
    onToggle();
  };
  const inputRef = useRef<{
    getCaretPosition: () => CaretPosition;
    focus: () => void;
  }>(null);
  const addButtonDisabled = getInputCharCounts(value) >= maxCharCount - 1;
  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus();
    }
  }, [autoFocus]);
  return (
    <Flex gap={2} alignItems="center" w="full" className="vms-line-root">
      <Box flex={1} overflow="hidden">
        <Flex
          gap={2}
          paddingY={1}
          paddingX={2}
          borderRadius="2xl"
          bgColor={hasFocus ? 'gray.700' : 'gray.900'}
          _hover={
            isDisabled
              ? undefined
              : {
                  bgColor: hasFocus ? 'gray.700' : 'gray.800',
                }
          }
        >
          {!canAddTag ? (
            <Box width="24px" />
          ) : (
            <Box
              flex={0}
              sx={{
                '.vms-line-root:hover &': {
                  opacity: 1,
                },
                opacity: hasFocus ? 1 : 0,
              }}
            >
              <InfoTooltip
                content={
                  addButtonDisabled && (
                    <FormattedMessage
                      defaultMessage="Can’t add tag. You have reached the {maxCharCount} character limit for this line."
                      id="CAKg3i"
                      values={{ maxCharCount }}
                    />
                  )
                }
              >
                <Popover
                  isOpen={isOpen}
                  onClose={onClose}
                  placement="bottom-start"
                  closeOnBlur
                >
                  <PopoverTrigger>
                    <IconButton
                      isDisabled={addButtonDisabled}
                      size="xs"
                      variant="unstyled"
                      color="white"
                      aria-label="Add tag"
                      icon={<ControlPointIcon width="24px" height="24px" />}
                      onClick={() => {
                        addClickHandler(
                          inputRef.current?.getCaretPosition() ?? null
                        );
                      }}
                      onFocus={() => {
                        setHasFocus(true);
                      }}
                      onBlur={() => {
                        onClose();
                        setHasFocus(false);
                      }}
                    />
                  </PopoverTrigger>
                  <PopoverContent width="fit-content">
                    <PopoverArrow />
                    <PopoverBody>
                      {typesForNodeSelection?.map((t) => (
                        <Tag
                          key={t.name}
                          size="sm"
                          variant="solid"
                          colorScheme="green"
                          backgroundColor="green.400"
                          marginTop="1px"
                          cursor="pointer"
                          onClick={() => onTypeSelectHandler(t.name)}
                        >
                          <TagLabel display="inline-block" whiteSpace="nowrap">
                            {t.label}
                          </TagLabel>
                        </Tag>
                      ))}
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </InfoTooltip>
            </Box>
          )}
          <Box flex={1} overflow="hidden">
            <InputWithTags
              ref={inputRef}
              value={value}
              maxCharCount={maxCharCount}
              onAddNode={onAddNode}
              onChange={onChange}
              onFocus={() => {
                setHasFocus(true);
                onFocus?.();
              }}
              onBlur={() => {
                setHasFocus(false);
                onBlur?.();
              }}
              isDisabled={isDisabled}
            />
          </Box>
        </Flex>
      </Box>
      <Box
        flex={0}
        lineHeight={0}
        sx={{
          '.vms-line-root:hover &': isDisabled
            ? undefined
            : {
                opacity: 1,
              },
          opacity: hasFocus ? 1 : 0,
        }}
      >
        <IconButton
          size="xs"
          padding={1}
          isRound
          variant="solid"
          colorScheme="red"
          backgroundColor="red.400"
          aria-label="Remove"
          onClick={onRemoveClick}
          icon={<DeleteIcon width="16px" height="16px" />}
          onFocus={() => {
            setHasFocus(true);
          }}
          onBlur={() => {
            setHasFocus(false);
          }}
          isDisabled={isDisabled}
        />
      </Box>
    </Flex>
  );
}

function InputWithTagsImpl(
  {
    value,
    maxCharCount,
    onChange,
    onFocus,
    onBlur,
    onAddNode,
    isDisabled,
  }: {
    value: ContentNode[];
    maxCharCount: number;
    onChange: (value: ContentNode[]) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    onAddNode: (
      type: string,
      selectedNode?: string
    ) => Promise<ContentNode | void>;
    isDisabled?: boolean;
  },
  ref: React.Ref<{ getCaretPosition: () => CaretPosition | null }>
) {
  const prevValueRef = useRef('');
  const rootRef = useRef<HTMLDivElement>(null);
  const templateBucketRef = useRef(document.createElement('div'));
  const templateContentRef = useRef('');
  useImperativeHandle(ref, () => ({
    focus: () => rootRef.current?.focus(),
    getCaretPosition: () => getCaretPosition(rootRef.current),
  }));
  useEffect(() => {
    const hasValueChanged = prevValueRef.current !== JSON.stringify(value);
    prevValueRef.current = JSON.stringify(value);
    const hasTemplateChanged =
      templateContentRef.current !== templateBucketRef.current.innerHTML;
    templateContentRef.current = templateBucketRef.current.innerHTML;
    if (rootRef.current && (hasValueChanged || hasTemplateChanged)) {
      setElementContent(rootRef.current, value, templateBucketRef.current);
    }
  });
  return (
    <>
      {createPortal(
        <Tag
          data-index="{{DATA_INDEX}}"
          contentEditable="false"
          suppressContentEditableWarning
          size="sm"
          variant="solid"
          colorScheme="green"
          backgroundColor="green.400"
          data-tag-type="{{TYPE}}"
          data-tag-value="{{VALUE}}"
          data-tag-label="{{LABEL}}"
          marginTop="1px"
          p={0}
          maxWidth="120px"
          cursor={isDisabled ? undefined : 'pointer'}
        >
          <TimerOnIcon mr={1} ml={2} />
          <TagLabel
            display="inline-block"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >{`{{CONTENT}}`}</TagLabel>
          <TagCloseButton
            data-remove-index="{{REMOVE_INDEX}}"
            ml={0}
            mr={1}
            isDisabled={isDisabled}
          />
        </Tag>,
        templateBucketRef.current
      )}
      <Box
        ref={rootRef}
        contentEditable={!isDisabled}
        onInput={(e) => {
          const inputContent = parseInput(e.currentTarget);
          if (
            (e.nativeEvent as InputEvent).inputType === 'insertParagraph' ||
            (e.nativeEvent as InputEvent).inputType === 'insertLineBreak' ||
            getInputCharCounts(inputContent) > maxCharCount ||
            !isAsciiCompatible(e.currentTarget.textContent ?? '')
          ) {
            setElementContent(
              rootRef.current,
              value,
              templateBucketRef.current
            );
            e.preventDefault();
            return;
          }
          onChange(inputContent);
        }}
        onClick={async (e) => {
          if (isDisabled) {
            return;
          }
          const removeIndex = getRemoveIndex(e.target);
          if (removeIndex !== undefined) {
            rootRef.current?.focus();
            const newValue = [...value];
            newValue.splice(removeIndex, 1);
            onChange(newValue);
          } else {
            const dataIndex = getDataIndex(e.target);
            if (dataIndex !== undefined) {
              const newNode = await onAddNode(
                value[dataIndex].type,
                value[dataIndex].value
              );
              if (newNode) {
                const newValue = [...value];
                newValue[dataIndex] = newNode;
                onChange(newValue);
              }
            }
          }
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        whiteSpace="nowrap"
        overflow="hidden"
        height="28px"
        paddingY="4px"
        lineHeight="20px"
        textAlign="center"
        fontSize="18px"
        fontWeight="bold"
        color="yellow.300"
        _focus={{
          outline: 'none',
        }}
      />
    </>
  );
}
function setElementContent(
  element: HTMLElement | null,
  value: ContentNode[],
  templateBucketRef: HTMLElement | null
) {
  const el = element;
  if (el) {
    const caretPosition = getCaretPosition(element);
    el.innerHTML = formatInput(value, templateBucketRef);
    if (caretPosition !== null) {
      setCaretPosition(el, caretPosition);
    }
  }
}

function getRemoveIndex(target: EventTarget | null): number | undefined {
  if (target instanceof Element) {
    const removeButtonElement = target.closest('[data-remove-index]');
    if (removeButtonElement) {
      return Number(removeButtonElement.getAttribute('data-remove-index'));
    }
  }
  return undefined;
}

function getDataIndex(target: EventTarget | null): number | undefined {
  if (target instanceof Element) {
    const dataElement = target.closest('[data-index]');
    if (dataElement) {
      return Number(dataElement.getAttribute('data-index'));
    }
  }
  return undefined;
}

function getCaretPosition(element: HTMLElement | null): CaretPosition | null {
  if (!element) {
    return null;
  }
  const windowSelection = window.getSelection();
  const hasCaret =
    windowSelection &&
    element.contains(windowSelection.anchorNode) &&
    element.contains(windowSelection.focusNode);
  if (!hasCaret) {
    return null;
  }

  const isCaretOnRoot = windowSelection?.focusNode === element;

  let charCount = 0;
  let caretPosition: CaretPosition | undefined;
  element.childNodes.forEach((node, nodeIndex) => {
    if (caretPosition) {
      return;
    }
    if (node.contains(windowSelection.focusNode)) {
      const nodeCharIndex =
        node.nodeType === Node.TEXT_NODE
          ? windowSelection.focusOffset
          : (node.textContent?.length ?? 0);
      caretPosition = {
        nodeIndex,
        nodeCharIndex,
        charIndex: charCount + nodeCharIndex,
      };
    } else if (
      isCaretOnRoot &&
      nodeIndex === (windowSelection?.focusOffset ?? 0) - 1
    ) {
      caretPosition = {
        nodeIndex,
        nodeCharIndex: node.textContent?.length ?? 0,
        charIndex: charCount + (node.textContent?.length ?? 0),
      };
    } else {
      charCount += node.textContent?.length ?? 0;
    }
  });
  return caretPosition ?? null;
}
function setCaretPosition(element: HTMLElement, position: CaretPosition) {
  element.focus();
  let nodeAtPosition: ChildNode | undefined;
  let charCount = 0;
  element.childNodes.forEach((node) => {
    if (nodeAtPosition) {
      return;
    }
    if (charCount + (node.textContent?.length ?? 0) >= position.charIndex) {
      nodeAtPosition = node;
    } else {
      charCount += node.textContent?.length ?? 0;
    }
  });
  if (nodeAtPosition?.nodeType === Node.TEXT_NODE) {
    window
      .getSelection()
      ?.collapse(
        nodeAtPosition,
        Math.min(
          nodeAtPosition.textContent?.length ?? 0,
          position.charIndex - charCount
        )
      );
  } else {
    const textNode = document.createTextNode('');
    if (nodeAtPosition) {
      nodeAtPosition.after(textNode);
    } else {
      element.appendChild(textNode);
    }
    window.getSelection()?.collapse(textNode, 0);
  }
}
function formatInput(
  value: ContentNode[],
  templateBucketRef: HTMLElement | null
) {
  const contentString = value
    .map((contentNode, itemIndex) => {
      if (contentNode.type === 'text') {
        return escapeHtmlChars(
          contentNode.label ?? contentNode.value?.toUpperCase()
        );
      }
      return templateBucketRef?.innerHTML
        .replace('{{TYPE}}', contentNode.type)
        .replace('{{VALUE}}', escapeHtmlChars(contentNode.value))
        .replace('{{LABEL}}', escapeHtmlChars(contentNode.label ?? ''))
        .replace(
          '{{CONTENT}}',
          escapeHtmlChars(contentNode.label ?? contentNode.value)
        )
        .replace('{{DATA_INDEX}}', itemIndex.toString())
        .replace('{{REMOVE_INDEX}}', itemIndex.toString());
    })
    .join('');
  // fix firefox not being able to position cursor after uneditable tag
  if (contentString.endsWith('>')) {
    return `${contentString}&nbsp;`;
  }
  // fix firefox trimming white space
  if (contentString.endsWith(' ')) {
    return `${contentString.substring(0, contentString.length - 1)}&nbsp;`;
  }
  return contentString;
}
function escapeHtmlChars(str: string) {
  return str
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;');
}
export function getInputCharCounts(contentNodes: ContentNode[]): number {
  let charCount = 0;
  contentNodes.forEach((contentNode) => {
    if (contentNode.type.startsWith('tag')) {
      charCount += tagCharSize;
    } else {
      // type is text
      charCount += contentNode.value.length;
    }
  });
  return charCount;
}

function parseInput(element: HTMLElement): ContentNode[] {
  const nodes = Array.from(element.childNodes);
  return nodes
    .filter(
      (node: ChildNode) =>
        node.nodeType === Node.TEXT_NODE || node.nodeType === Node.ELEMENT_NODE
    )
    .map((node: ChildNode) => {
      if (IsTagElement(node)) {
        return {
          type: node.getAttribute('data-tag-type') ?? '',
          value: node.getAttribute('data-tag-value') ?? '',
          label: node.getAttribute('data-tag-label') ?? undefined,
        };
      }
      return {
        type: 'text',
        value: node.textContent?.toUpperCase() ?? '',
      };
    });
}

// test for charcode range from 20 to 126 (space to tilde), which are ASCII characters
function isAsciiCompatible(str: string) {
  return /([^ -~\s])+/g.test(str) === false;
}
