import { ReactNode, useRef } from 'react';
import { Box, Flex, VStack, useBreakpointValue } from '@chakra-ui/react';
import { FormattedMessage, defineMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import {
  DecreasingListIcon,
  MapPinIcon,
} from 'design-system/atoms/custom-icons';
import SearchBar, { SearchBarProps } from 'design-system/organisms/search-bar';
import { DropDownSortProps } from 'design-system/molecules/drop-down-sort';
import { CompareSortValuesMode } from '../../utils/sort';
import LayoutResponsiveSidebar from './LayoutResponsiveSidebar';
import TabsList from './TabsList';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';

export const NAME_OPTION_VALUE = 'NAME';
export const nameOption = {
  sortOptionMessage: defineMessage({
    defaultMessage: 'Name',
    id: 'dF6kXr',
    description: 'Sort option for name',
  }),
  value: NAME_OPTION_VALUE,
  enabled: true,
};

export const LAST_EDITED_OPTION_VALUE = 'LAST_EDITED';
export const lastEditedOption = {
  sortOptionMessage: defineMessage({
    defaultMessage: 'Last Edited',
    id: 'HadfAD',
    description: 'Sort option for last edited',
  }),
  value: LAST_EDITED_OPTION_VALUE,
  enabled: true,
};

type LayoutNetworkMonitoringProps = Pick<
  SearchBarProps,
  'searchTerm' | 'setSearchTerm' | 'sortOptions' | 'setSelectedSortOption'
> & {
  insightId: string;
  sidebarContent: ReactNode;
  mapContent: ReactNode;
  selectedSortOption: DropDownSortProps['selectedSortOption'];
  sortDirection: CompareSortValuesMode;
  setSortDirection: (CompareSortValuesMode) => void;
  defaultOpen?: boolean;
};

export default function LayoutNetworkMonitoring({
  insightId,
  searchTerm,
  setSearchTerm,
  sortOptions,
  selectedSortOption,
  setSelectedSortOption,
  sortDirection,
  setSortDirection,
  sidebarContent,
  mapContent,
  defaultOpen = false,
}: LayoutNetworkMonitoringProps) {
  const analytics = useAnalytics();
  const { overviewTab } = useParams();
  const isMobile = useBreakpointValue({
    md: false,
    base: true,
  });
  const debouncedUseSearch = useRef(
    debounce(() => {
      analytics.track('Site Search Input Changed', {
        referrer: `NetworkMonitoring - ${overviewTab}`,
      });
    }, 5000)
  );
  return (
    <LayoutResponsiveSidebar
      defaultOpen={defaultOpen}
      mainTitle={
        <VStack spacing={0}>
          <MapPinIcon />
          <Box>
            <FormattedMessage
              defaultMessage="Map"
              id="1VsP1A"
              description="Network monitoring tab labels: Map"
            />
          </Box>
        </VStack>
      }
      sidebarTitle={
        <VStack spacing={0}>
          <DecreasingListIcon />
          <Box>
            <FormattedMessage
              defaultMessage="Details"
              id="pJ0Xpa"
              description="Network monitoring tab labels: Details"
            />
          </Box>
        </VStack>
      }
      sidebar={
        <Flex
          flexDirection="column"
          backgroundColor="white"
          data-testid="networkmonitoring-sidebar"
          height="100%"
        >
          <Box minW="160px" h={isMobile ? '96px' : '48px'} />
          <VStack alignItems="stretch" padding={4} spacing={4}>
            <TabsList insightId={insightId} overviewTab={overviewTab} />
            <SearchBar
              searchTerm={searchTerm}
              setSearchTerm={(term) => {
                setSearchTerm(term);
                debouncedUseSearch.current();
              }}
              sortDirectionIncrease={
                sortDirection === CompareSortValuesMode.LOWEST_FIRST
              }
              setSortDirectionIncrease={(isIncrease) => {
                setSortDirection(
                  isIncrease
                    ? CompareSortValuesMode.LOWEST_FIRST
                    : CompareSortValuesMode.HIGHEST_FIRST
                );
                analytics.track('Site List Direction Changed', {
                  referrer: `NetworkMonitoring - ${overviewTab}`,
                  direction: isIncrease
                    ? CompareSortValuesMode.LOWEST_FIRST
                    : CompareSortValuesMode.HIGHEST_FIRST,
                });
              }}
              selectedSortOption={selectedSortOption}
              setSelectedSortOption={(optionValue) => {
                setSelectedSortOption(optionValue);
                analytics.track('Site List Sorted', {
                  referrer: `NetworkMonitoring - ${overviewTab}`,
                  sortOption: optionValue,
                });
              }}
              sortOptions={sortOptions}
            />
          </VStack>
          <Box flex="1 1 0" overflow="auto">
            {sidebarContent}
          </Box>
        </Flex>
      }
    >
      {mapContent}
    </LayoutResponsiveSidebar>
  );
}
