import useSWR from 'swr';
import { BaselineResponse } from '@webapp/bff/src/types/baseline';
import { useMemo } from 'react';
import { trpc } from '../config/trpc';

export default function useSiteBaselinesData(
  siteId?: string,
  suspense = false,
  refreshInterval?: number
) {
  const { data, mutate, isValidating, error } = useSWR<BaselineResponse>(
    siteId ? `useSiteBaselinesData-${siteId}` : null,
    !siteId ? null : () => trpc.site.getBaselines.query({ siteId }),
    {
      suspense,
      refreshInterval,
    }
  );

  const sortedData = useMemo(() => {
    if (!data) {
      return data;
    }
    return {
      ...data,
      baselines: [...data.baselines].sort((a, b) => {
        if (a.isDefault) {
          return -1;
        }
        if (b.isDefault) {
          return 1;
        }
        if (
          a.baselineType === '4WEEKS_AVERAGE_BASELINE' &&
          b.baselineType !== '4WEEKS_AVERAGE_BASELINE'
        ) {
          return -1;
        }
        if (
          b.baselineType === '4WEEKS_AVERAGE_BASELINE' &&
          a.baselineType !== '4WEEKS_AVERAGE_BASELINE'
        ) {
          return 1;
        }
        return b.versionCreatedAt - a.versionCreatedAt;
      }),
    };
  }, [data]);

  const mutateThenUpdate =
    (serverOp) =>
    async (
      ...args: Parameters<typeof serverOp>
    ): Promise<ReturnType<typeof serverOp>> => {
      const response = await serverOp(...args);
      await mutate();
      return response;
    };

  return {
    data: sortedData,
    error,
    isValidating,
    requestBaselineCreation: (baselineProps: {
      name: string;
      startDate?: string;
      endDate?: string;
      description?: string;
      baselineType: 'SIMPLE_RANGE_BASELINE' | '4WEEKS_AVERAGE_BASELINE';
      excludedDates?: string[];
    }): Promise<void> =>
      mutateThenUpdate(trpc.site.createBaseLine.mutate)({
        siteId,
        ...baselineProps,
        name:
          baselineProps.baselineType === 'SIMPLE_RANGE_BASELINE'
            ? baselineProps.name
            : '4 Weeks Average',
        description:
          baselineProps.baselineType === 'SIMPLE_RANGE_BASELINE'
            ? ''
            : (baselineProps.description ?? ''),
      }),
    updateBaseline: (baselineProps: {
      baselineId: string;
      name: string;
      description: string;
      startDate: string;
      endDate: string;
    }): Promise<void> =>
      mutateThenUpdate(trpc.site.updateBaselineWithVersion.mutate)(
        baselineProps
      ),
    deleteBaseline: (baselineId: string) =>
      mutateThenUpdate(trpc.site.deleteBaseline.mutate)({ baselineId }),
    deleteBaselineRequest: (jobId: string) =>
      mutateThenUpdate(trpc.site.deleteBaselineRequest.mutate)({ jobId }),
    setDefaultBaseline: (baselineId: string) =>
      mutateThenUpdate(trpc.site.updateBaselineDefaultStatus.mutate)({
        baselineId,
        isDefault: true,
      }),
  };
}
