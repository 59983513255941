import { useMemo, useState } from 'react';
import { Box, Checkbox, Flex, Text } from '@chakra-ui/react';
import { defineMessage, FormattedMessage } from 'react-intl';
import useOrgRoutes, { OrgRoutesOptions } from '../../data/useOrgRoutes';
import { CompareSortValuesMode } from '../../utils/sort';
import LayoutNetworkMonitoring, {
  NAME_OPTION_VALUE,
  nameOption,
} from './LayoutNetworkMonitoring';
import RoutesMap from './RoutesMap';
import {
  DataHandlerFeedback,
  hasDataHandlerFeedBack,
} from '../../utils/dataHandler';
import RoutesList from './RoutesList';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import BaseMap from '../BaseMap';
import WrapComponent from '../WrapComponent';

export const ANOMALIES_OPTION_VALUE = 'ANOMALIES';
const anomaliesOption = {
  sortOptionMessage: defineMessage({
    defaultMessage: 'Anomalies',
    id: 'DtDhVt',
    description: 'Sort option for anomalies',
  }),
  value: ANOMALIES_OPTION_VALUE,
  enabled: true,
};

const sortByConfig: Record<string, OrgRoutesOptions['sortBy']> = {
  [NAME_OPTION_VALUE]: 'routeName',
  [ANOMALIES_OPTION_VALUE]: 'anomalyScore',
};

export default function MonitoringRoutes({
  insightId,
  showInactiveRoutes = true,
  Wrapper,
}: {
  insightId: string;
  showInactiveRoutes?: boolean;
  Wrapper: typeof LayoutNetworkMonitoring;
}) {
  const analytics = useAnalytics();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSortOption, setSelectedSortOption] = useState(
    ANOMALIES_OPTION_VALUE
  );
  const [sortDirection, setSortDirection] = useState(
    CompareSortValuesMode.LOWEST_FIRST
  );
  const [showInactive, setShowInactive] = useState(false);
  const routesDataHandler = useOrgRoutes(insightId, {
    includeDisabled: showInactiveRoutes || showInactive,
    searchTerm,
    sortDirection,
    sortBy: sortByConfig[selectedSortOption] ?? 'routeName',
  });
  const [highlightedTrackId, setHighlightedTrackId] = useState<string>();

  const toggleShowInactive = () => {
    setShowInactive((inactive) => {
      analytics.track('Route List Filtered', {
        referrer: 'NetworkMonitoring',
        active: !inactive,
      });
      return !inactive;
    });
  };
  const mapComponentProps = useMemo(
    () => ({
      insightId,
      routes: showInactive
        ? (routesDataHandler.data ?? [])
        : (routesDataHandler.data ?? []).filter((r) => r.enabled),
      highlightedTrackId,
      onHighlightedTrackIdChange: setHighlightedTrackId,
    }),
    [insightId, routesDataHandler.data, showInactive, highlightedTrackId]
  );

  const mapComponent = (
    <WrapComponent
      Wrapper={BaseMap}
      Component={RoutesMap}
      componentProps={mapComponentProps}
    />
  );

  return (
    <Wrapper
      insightId={insightId}
      searchTerm={searchTerm}
      sortOptions={[nameOption, anomaliesOption]}
      setSearchTerm={setSearchTerm}
      selectedSortOption={selectedSortOption}
      setSelectedSortOption={setSelectedSortOption}
      sortDirection={sortDirection}
      setSortDirection={setSortDirection}
      sidebarContent={
        <>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            width="full"
            paddingX={4}
            paddingBottom={4}
          >
            <Text fontSize="sm" margin={0}>
              <FormattedMessage
                defaultMessage="Live"
                id="RkXzpN"
                description="Live time period"
              />
            </Text>
            <Checkbox
              isChecked={showInactive}
              onChange={toggleShowInactive}
              data-testid="search-bar-show-inactive"
              size="lg"
            >
              <Box fontSize="xs">
                {showInactiveRoutes ? (
                  <FormattedMessage
                    defaultMessage="Show inactive on the map"
                    id="m6dgyO"
                    description="Show inactive route on the map checkbox label"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Show inactive"
                    id="CsolMH"
                    description="Show inactive route checkbox label"
                  />
                )}
              </Box>
            </Checkbox>
          </Flex>
          {hasDataHandlerFeedBack([routesDataHandler]) ? (
            <DataHandlerFeedback dataHandlersParam={[routesDataHandler]} />
          ) : (
            <RoutesList
              routes={routesDataHandler.data ?? []}
              searchTerm={searchTerm}
              onHighlightedTrackIdChange={setHighlightedTrackId}
            />
          )}
        </>
      }
      mapContent={
        hasDataHandlerFeedBack([routesDataHandler]) ? (
          <DataHandlerFeedback dataHandlersParam={[routesDataHandler]} />
        ) : (
          mapComponent
        )
      }
    />
  );
}
