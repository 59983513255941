import useSWR, { SWRResponse } from 'swr';
import { pathSitesView } from '../constants/path';
import { components } from '../types/schema';
import { envConfig } from '../config/envConfig';
import { CompareSortValuesMode } from '../utils/sort';
import useRouteMaxJourneyTimeChangeData from './useRouteMaxJourneyTimeChangeData';

export type RouteSummary = components['schemas']['RouteSummary'] & {
  siteUrl: string;
  anomalyScore?: number | null;
  anomalyScorePercentage?: string;
  anomalyScoreColor?: string;
};

export type OrgRoutesResult = SWRResponse<RouteSummary[]>;

export interface OrgRoutesOptions {
  includeDisabled?: boolean;
  searchTerm?: string;
  sortBy?: keyof RouteSummary;
  sortDirection?: CompareSortValuesMode;
}

const refreshInterval = 5 * 60 * 1000; // 5 minutes in milliseconds

export default function useOrgRoutes(
  insightId: string,
  options?: OrgRoutesOptions
): OrgRoutesResult {
  const {
    includeDisabled = false,
    searchTerm = '',
    sortBy = 'routeName',
    sortDirection = CompareSortValuesMode.LOWEST_FIRST,
  } = options ?? {};
  const routesResult = useSWR<RouteSummary[]>(
    `${envConfig.API_URL}/api/v1/home/${insightId}/routes`,
    {
      refreshInterval,
      refreshWhenHidden: true,
    }
  );
  const maxJourneyTimeChangesResult =
    useRouteMaxJourneyTimeChangeData(insightId);

  const maxJourneyTimeChangesByTrackId =
    maxJourneyTimeChangesResult.data?.reduce<Record<string, number>>(
      (accumulator, current) => {
        if (
          current.trackId !== undefined &&
          current.maxJourneyTimeChange !== undefined
        ) {
          accumulator[current.trackId] = current.maxJourneyTimeChange;
        }
        return accumulator;
      },
      {}
    ) ?? {};

  const trackIdsBySiteId: Record<number, string[]> =
    routesResult.data?.reduce((accumulator, current) => {
      if (current.siteId !== undefined && current.trackId !== undefined) {
        if (accumulator[current.siteId] === undefined) {
          accumulator[current.siteId] = [];
        }
        accumulator[current.siteId].push(current.trackId);
      }
      return accumulator;
    }, {}) ?? {};

  function siteUrl(route: RouteSummary): string {
    const otherTrackIds = trackIdsBySiteId[route.siteId!].filter(
      (trackId) => trackId !== route.trackId
    );
    return `${pathSitesView(insightId)}?siteId=${
      route.siteId
    }&hidden=${otherTrackIds.join(',')}`;
  }

  const lowerCaseSearchTerm = searchTerm.toLowerCase();
  const data = routesResult.data
    ?.filter((route) => route.enabled || includeDisabled)
    .filter(
      (route) =>
        lowerCaseSearchTerm === undefined ||
        route.routeName?.toLowerCase().includes(lowerCaseSearchTerm) ||
        route.siteName?.toLocaleLowerCase().includes(searchTerm)
    )
    .map((route) => {
      const anomalyScore = route.trackId
        ? maxJourneyTimeChangesByTrackId[route.trackId]
        : undefined;
      const anomalyScorePercentage = anomalyScore
        ? getAnomalyScorePercentage(anomalyScore)
        : undefined;
      const anomalyScoreColor = anomalyScore
        ? getAnomalyScoreColor(anomalyScore)
        : undefined;
      return {
        ...route,
        siteUrl: siteUrl(route),
        anomalyScore,
        anomalyScorePercentage,
        anomalyScoreColor,
      };
    });
  data?.sort((route1, route2) => {
    const value = sortValue(route1, route2, sortBy);
    return sortDirection === CompareSortValuesMode.LOWEST_FIRST
      ? value
      : -value;
  });
  return {
    ...routesResult,
    data,
  };
}

function sortValue(
  route1: RouteSummary,
  route2: RouteSummary,
  sortBy: keyof RouteSummary
): number {
  if (sortBy === 'anomalyScore') {
    return (route2.anomalyScore ?? 0) - (route1.anomalyScore ?? 0);
  }
  if ((route1[sortBy] ?? '') < (route2[sortBy] ?? '')) {
    return -1;
  }
  if ((route1[sortBy] ?? '') > (route2[sortBy] ?? '')) {
    return 1;
  }
  return 0;
}

export function getAnomalyScorePercentage(anomalyScore: number): string {
  return anomalyScore < 1
    ? `-${((1 - anomalyScore) * 100).toFixed(0)}%`
    : `+${((anomalyScore - 1) * 100).toFixed(0)}%`;
}

export function getAnomalyScoreColor(anomalyScore: number): string {
  if (anomalyScore >= 1.55) {
    return 'red.900';
  }
  if (anomalyScore >= 1.3) {
    return 'red.400';
  }
  if (anomalyScore >= 1.05) {
    return 'orange.300';
  }
  return 'blue.400';
}
