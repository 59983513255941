import { ListItem } from 'design-system/molecules/list-item';
import { Box, Button, Tag, useDisclosure, VStack } from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import ContextMenu from 'design-system/molecules/context-menu';
import ConfirmationModal from 'design-system/molecules/confirmation-modal';
import { endOfDay, startOfToday } from 'date-fns';
import { createRef, ReactNode, useState } from 'react';
import {
  BaselineConfig,
  BaselineJobRequest,
} from '@webapp/bff/src/types/baseline';
import { DATE_PRESET_SHORT_FULL_YEAR } from '../../utils/dateUtils';
import useSiteBaselinesData from '../../data/useSiteBaselinesData';
import {
  DataHandlerFeedback,
  hasDataHandlerFeedBack,
} from '../../utils/dataHandler';
import { CreateBaselineModal } from './CreateBaselineModal';
import { useActionWithFeedback } from '../../hooks/useActionWithFeedback';
import BaselineForm from './BaselineForm';
import { catchValidationError } from '../../utils/form';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import useFeatureSwitch, {
  FeatureSwitchState,
} from '../../hooks/useFeatureSwitch';
import { FEATURE_SITE_IMPACT_UI_BASELINES_PHASE_2 } from '../../constants/features';
import BaselineChartsModal from './BaselineChartsModal';
import { SiteInfo } from '../../data/useSiteData';
import { Route } from '../../types/route';

export default function Baselines({
  siteProps,
  routes,
  minBaselineDate,
}: {
  siteProps?: Pick<SiteInfo, 'siteId' | 'siteName' | 'siteTimeZone'>;
  routes?: Route[];
  minBaselineDate?: string;
}) {
  const featureBaselinesPhase2 = useFeatureSwitch(
    FEATURE_SITE_IMPACT_UI_BASELINES_PHASE_2
  );
  const isFeatureBaselinesPhase2Enabled =
    featureBaselinesPhase2 === FeatureSwitchState.ON;
  const baselinesDataHandler = useSiteBaselinesData(
    siteProps?.siteId.toString(),
    false,
    30 * 1000
  );
  const baselines = baselinesDataHandler.data?.baselines ?? [];
  const { formatMessage } = useIntl();
  const { track } = useAnalytics();
  const createModalDisclosure = useDisclosure();
  const [performingBaselineAction, setPerformingBaselineAction] = useState<
    string | null
  >(null);
  const [performingBaselineRequestAction, setPerformingBaselineRequestAction] =
    useState<string | null>(null);
  const [viewingBaselineId, setViewingBaselineId] = useState<string | null>(
    null
  );
  const viewingBaselineProps = baselines.find(
    (baseline) => baseline.baselineId === viewingBaselineId
  );
  const [updatingBaselineId, setUpdatingBaselineId] = useState<string | null>(
    null
  );
  const [deletingBaselineId, setDeletingBaselineId] = useState<string | null>(
    null
  );
  const updatingBaselineProps = baselines.find(
    (baseline) => baseline.baselineId === updatingBaselineId
  );
  const updateBaselineFormActionRef = createRef<() => Promise<void>>();
  const updateBaselineAction = useActionWithFeedback({
    action: (values) => {
      baselinesDataHandler.updateBaseline({
        baselineId: updatingBaselineId,
        ...values,
      });
      track('Baseline Updated', {
        referrer: 'Manage baselines',
        feature: 'Baselines',
      });
    },
    onSuccess: () => {
      setUpdatingBaselineId(null);
    },
  });
  const setDefaultBaselineAction = useActionWithFeedback({
    action: (baselineId: string) => {
      baselinesDataHandler.setDefaultBaseline(baselineId);
      track('Baseline Set Default', {
        referrer: 'Manage baselines',
        feature: 'Baselines',
      });
    },
    onActionWillPerform: (baselineId: string) => {
      setPerformingBaselineAction(baselineId);
    },
    onComplete: () => {
      setPerformingBaselineAction(null);
    },
  });

  const deleteBaselineAction = useActionWithFeedback({
    action: async (baselineId) => {
      baselinesDataHandler.deleteBaseline(baselineId);
      track('Baseline Deleted', {
        referrer: 'Manage baselines',
        feature: 'Baselines',
      });
    },
    onSuccess: () => {
      setDeletingBaselineId(null);
    },
  });
  const deleteBaselineRequestAction = useActionWithFeedback({
    action: async (jobId: string) => {
      baselinesDataHandler.deleteBaselineRequest(jobId);
      track('Baseline Deletion Requested', {
        referrer: 'Manage baselines',
        feature: 'Baselines',
      });
    },
    onActionWillPerform: (jobId: string) => {
      setPerformingBaselineRequestAction(jobId);
    },
    onComplete: () => {
      setPerformingBaselineRequestAction(null);
    },
  });

  if (hasDataHandlerFeedBack(baselinesDataHandler)) {
    return <DataHandlerFeedback dataHandlersParam={[baselinesDataHandler]} />;
  }
  return (
    <>
      <CreateBaselineModal
        baselineCreationTypes={
          baselinesDataHandler.data?.baselineCreationTypes ?? [
            'SIMPLE_RANGE_BASELINE',
          ]
        }
        createBaseline={baselinesDataHandler.requestBaselineCreation}
        isOpen={createModalDisclosure.isOpen}
        onClose={createModalDisclosure.onClose}
        analyticsReferrer="Manage baselines"
        minBaselineDate={minBaselineDate}
        isFeatureBaselinesPhase2Enabled={isFeatureBaselinesPhase2Enabled}
      />
      <BaselineChartsModal
        siteProps={siteProps}
        routes={routes}
        baseline={viewingBaselineProps}
        isOpen={viewingBaselineId !== null}
        onClose={() => {
          setViewingBaselineId(null);
        }}
        onEditClick={() => {
          setUpdatingBaselineId(viewingBaselineId);
        }}
      />
      <ConfirmationModal
        title={formatMessage({
          defaultMessage: 'Update baseline',
          id: 'SmX7ob',
        })}
        body={
          updatingBaselineProps && (
            <BaselineForm
              defaultValues={{
                name: updatingBaselineProps.name,
                description: updatingBaselineProps.description,
                startDate: updatingBaselineProps.config?.startDate,
                endDate: updatingBaselineProps.config?.endDate,
                excludedDates: updatingBaselineProps.config?.excludedDates,
                baselineType: updatingBaselineProps.baselineType,
              }}
              onSubmit={updateBaselineAction.performAction}
              submitActionRef={updateBaselineFormActionRef}
              minDate={minBaselineDate}
              baselineCreationTypes={
                updatingBaselineProps
                  ? [updatingBaselineProps.baselineType]
                  : []
              }
              isFeatureBaselinesPhase2Enabled={isFeatureBaselinesPhase2Enabled}
            />
          )
        }
        onConfirm={() =>
          updateBaselineFormActionRef.current?.().catch(catchValidationError)
        }
        onCancel={() => {
          setUpdatingBaselineId(null);
        }}
        open={updatingBaselineId !== null}
        size="4xl"
      />
      <ConfirmationModal
        title={formatMessage({
          defaultMessage: 'Delete baseline',
          id: 'N9xtSp',
        })}
        body={formatMessage({
          defaultMessage:
            'Are you sure you want to delete this baseline? This action cannot be undone.',
          id: 'kXe94N',
        })}
        onConfirm={() => deleteBaselineAction.performAction(deletingBaselineId)}
        onCancel={() => {
          setDeletingBaselineId(null);
        }}
        open={deletingBaselineId !== null}
        confirmButtonColorScheme="red"
      />
      <VStack alignItems="stretch" spacing={4}>
        <Box>
          <Button
            size="sm"
            colorScheme="greenDark"
            variant="solid"
            onClick={createModalDisclosure.onOpen}
          >
            <FormattedMessage defaultMessage="New baseline" id="qUa6D8" />
          </Button>
        </Box>
        <VStack alignItems="stretch" spacing={4}>
          {baselines.map((baseline) => (
            <BaselineItem
              key={baseline.baselineId}
              baseline={baseline}
              actionsContent={
                <ContextMenu
                  items={[
                    ...(!isFeatureBaselinesPhase2Enabled
                      ? []
                      : [
                          {
                            key: 'view',
                            label: formatMessage({
                              defaultMessage: 'View',
                              id: 'FgydNe',
                            }),
                            action: () => {
                              setViewingBaselineId(baseline.baselineId);
                            },
                          },
                        ]),
                    ...(baseline.baselineType === '4WEEKS_AVERAGE_BASELINE'
                      ? []
                      : [
                          {
                            key: 'edit',
                            label: formatMessage({
                              defaultMessage: 'Edit',
                              id: 'wEQDC6',
                            }),
                            action: () => {
                              setUpdatingBaselineId(baseline.baselineId);
                            },
                          },
                        ]),
                    {
                      key: 'delete',
                      label: formatMessage({
                        defaultMessage: 'Delete',
                        id: 'K3r6DQ',
                      }),
                      action: () => {
                        setDeletingBaselineId(baseline.baselineId);
                      },
                    },
                    ...(baseline.isDefault
                      ? []
                      : [
                          {
                            key: 'set-default',
                            label: formatMessage({
                              defaultMessage: 'Set as default',
                              id: 'z+Zrln',
                            }),
                            action: () => {
                              setDefaultBaselineAction.performAction(
                                baseline.baselineId
                              );
                            },
                          },
                        ]),
                  ]}
                  isLoading={performingBaselineAction === baseline.baselineId}
                />
              }
            />
          ))}
        </VStack>
        {baselinesDataHandler.data &&
          baselinesDataHandler.data.baselineRequests.length > 0 && (
            <VStack alignItems="stretch" spacing={2}>
              <Box fontWeight={500}>
                <FormattedMessage
                  defaultMessage="Pending baseline requests"
                  id="cI5uRb"
                />
              </Box>
              <VStack alignItems="stretch" spacing={4}>
                {baselinesDataHandler.data?.baselineRequests.map((request) => (
                  <BaselineItem
                    key={request.jobId}
                    baseline={request}
                    actionsContent={
                      <ContextMenu
                        items={[
                          {
                            key: 'delete',
                            label: formatMessage({
                              defaultMessage: 'Delete',
                              id: 'K3r6DQ',
                            }),
                            action: () =>
                              deleteBaselineRequestAction.performAction(
                                request.jobId
                              ),
                          },
                        ]}
                        isLoading={
                          performingBaselineRequestAction === request.jobId
                        }
                      />
                    }
                  />
                ))}
              </VStack>
            </VStack>
          )}
      </VStack>
    </>
  );
}

function BaselineItem({
  baseline,
  actionsContent,
}: {
  baseline: BaselineConfig | BaselineJobRequest;
  actionsContent?: ReactNode;
}) {
  const { formatDate, formatMessage } = useIntl();
  return (
    <ListItem
      title={
        <Box>
          {isBaselineConfig(baseline) && baseline.isDefault && (
            <StarIcon color="yellow.400" mb="4px" mr={1} />
          )}
          {baseline.name}
          {isBaselineConfig(baseline) &&
            baseline.baselineType !== '4WEEKS_AVERAGE_BASELINE' && (
              <Tag bgColor="green.400" size="sm" mt="1px" ml={1}>
                V{baseline.version}
                {baseline.version > 1 && baseline.versionCreatedAt && (
                  <>
                    {` `}
                    <Box as="span" color="green.100">
                      &nbsp;-&nbsp;
                      {formatDate(
                        new Date(baseline.versionCreatedAt),
                        DATE_PRESET_SHORT_FULL_YEAR
                      )}
                    </Box>
                  </>
                )}
              </Tag>
            )}
        </Box>
      }
      description={
        !isBaselineConfig(baseline) &&
        baseline.config?.endDate &&
        endOfDay(baseline.config.endDate).getTime() < startOfToday().getTime()
          ? formatMessage({
              defaultMessage:
                'Your baseline will be available in the next 5 minutes',
              id: 'pxuejO',
            })
          : baseline.description
      }
      content={
        baseline.config &&
        baseline.config.startDate &&
        baseline.config.endDate && (
          <Box fontSize="xs" fontWeight="500">
            {formatDate(baseline.config.startDate, DATE_PRESET_SHORT_FULL_YEAR)}{' '}
            - {formatDate(baseline.config.endDate, DATE_PRESET_SHORT_FULL_YEAR)}
          </Box>
        )
      }
      rightActions={actionsContent}
    />
  );
}
function isBaselineConfig(
  baseline: BaselineConfig | BaselineJobRequest
): baseline is BaselineConfig {
  return (baseline as BaselineConfig).baselineId !== undefined;
}
