import { Tab, TabList, Tabs } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ReactNode } from 'react';
import { pathSitesOverview, SitesOverviewTabId } from '../../constants/path';
import { FEATURE_PROJECTS_TABLE } from '../../constants/features';
import useFeatureSwitch, {
  FeatureSwitchState,
} from '../../hooks/useFeatureSwitch';

interface TabConfig {
  path: SitesOverviewTabId;
  label: ReactNode;
}

export default function TabsList({
  overviewTab,
  insightId,
}: {
  overviewTab?: string;
  insightId: string;
}) {
  const navigate = useNavigate();
  const featureProjectsTable = useFeatureSwitch(FEATURE_PROJECTS_TABLE);
  const isFeatureProjectsTable = featureProjectsTable === FeatureSwitchState.ON;

  const tabsConfig: TabConfig[] = isFeatureProjectsTable
    ? [
        {
          path: 'site-projects',
          label: <FormattedMessage defaultMessage="Sites" id="DDhP4z" />,
        },
        {
          path: 'routes',
          label: <FormattedMessage defaultMessage="Routes" id="eyx5By" />,
        },
        {
          path: 'scorecards',
          label: <FormattedMessage defaultMessage="Scorecards" id="6F8r+W" />,
        },
        {
          path: 'sites-performance',
          label: <FormattedMessage defaultMessage="Performance" id="AA5h7P" />,
        },
      ]
    : [
        {
          path: 'sites',
          label: <FormattedMessage defaultMessage="Sites" id="DDhP4z" />,
        },
        {
          path: 'routes',
          label: <FormattedMessage defaultMessage="Routes" id="eyx5By" />,
        },
        {
          path: 'scorecards',
          label: <FormattedMessage defaultMessage="Scorecards" id="6F8r+W" />,
        },
      ];
  const tabIndex = tabsConfig.findIndex(
    (tabConfig) => tabConfig.path === overviewTab
  );
  return (
    <Tabs
      index={tabIndex > -1 ? tabIndex : 0}
      onChange={(index) => {
        navigate(pathSitesOverview(insightId, tabsConfig[index].path, null));
      }}
      colorScheme="green"
    >
      <TabList>
        {tabsConfig.map(({ path, label }) => (
          <Tab key={path} fontSize="sm" fontWeight="600">
            {label}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
}
