import useSWR from 'swr';
import { VmsPreview } from '@configuration/api/src/types/vms';
import type { MessageTypeConfig } from '@configuration/lib-vms/src/message-types';
import { trpc } from '../config/trpc';

export default function useVmsPreview(
  input: {
    vmsConfigId?: string;
    overrideMessageConfig?: MessageTypeConfig;
    isLive: boolean;
  },
  suspense = true
) {
  const { data, isValidating, error } = useSWR<VmsPreview | undefined>(
    input.vmsConfigId
      ? `useVmsPreview-${input.vmsConfigId}-${input.overrideMessageConfig ? JSON.stringify(input.overrideMessageConfig) : ''}`
      : null,
    !input.vmsConfigId
      ? null
      : () =>
          trpc.vms.getVmsPreview.query({
            vmsConfigId: input.vmsConfigId!,
            overrideMessageConfig: input.overrideMessageConfig,
          }),
    {
      suspense,
      refreshInterval: input.isLive ? 5 * 60 * 1000 : undefined,
      revalidateOnFocus: input.isLive,
      revalidateIfStale: input.isLive,
    }
  );

  return {
    data,
    error,
    isValidating,
  };
}
