import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Flex, Spinner, useSteps, useToken } from '@chakra-ui/react';
import { createRef, Fragment, ReactNode, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ActiveVms } from '@webapp/bff/src/types/vms';
import ConfirmationModal, {
  ConfirmationModalProps,
} from 'design-system/molecules/confirmation-modal';
import { pathSiteVmsBoard, pathSiteVmsBoards } from '../../constants/path';
import LayoutSmartVms from './LayoutSmartVms';
import useRoutesData from '../../data/useRoutesData';
import AnimatedRoute from '../AnimatedRoute';
import { colorsSortedByKey, parseWkt } from '../../utils/routes';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import useSiteVmsData from '../../data/useSiteVmsData';
import StepBoardDetails from './StepBoardDetails';
import MapLayerVmsBoardsWithPreview from './VmsMapLayerWithPreview';
import StepBoardMessageType from './StepBoardMessageType';
import StepBoardScheduleConnection from './StepBoardSchedule';
import useSiteData from '../../data/useSiteData';
import { useUserRoles } from '../../hooks/useUserRoles';
import {
  CreateVmsSteps,
  equalFieldValues,
  isBoardLessCapable,
  resetMessageFields,
} from './utils';
import StepBoardOverrideFallback from './StepBoardOverrideFallback';
import WizardLayout, { PreventWizardStepExit } from '../WizardLayout';
import { catchValidationErrorAndPreventStepExit } from '../../utils/form';

export default function SmartVmsCreateBoard({
  Wrapper,
}: {
  Wrapper: typeof LayoutSmartVms;
}) {
  const { insightId, siteId, vmsConfigId } = useParams();
  const { isAdmin } = useUserRoles();
  const { search } = useLocation();

  const defaultStepNameParam =
    new URLSearchParams(search).get('step')?.toUpperCase() ?? 'DETAILS';
  const stepsManager = useSteps({
    index: CreateVmsSteps[defaultStepNameParam],
  });
  const { track } = useAnalytics();
  if (!insightId || !siteId) {
    throw new Error('insightId and siteId are required');
  }
  const routesDataHandler = useRoutesData(Number(siteId));
  const vmsDataHandler = useSiteVmsData(siteId);
  const siteDataHandler = useSiteData({ orgId: insightId, siteId });
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const currentVmsData = vmsDataHandler.data?.find(
    (vms) => vms.vmsConfigId === vmsConfigId
  );
  const goToVmsPage = () => {
    navigate(pathSiteVmsBoards(insightId, siteId));
  };

  const [mainPanelContent, setMainPanelContent] = useState<ReactNode>();
  const [selectedRoutes, setSelectedRoutes] = useState<string[] | undefined>();

  const updateVmsData = async (
    updatedVmsFields: Partial<ActiveVms>,
    referrer: string
  ) => {
    if (!currentVmsData) {
      throw new Error('VMS data is missing');
    }
    if (equalFieldValues(updatedVmsFields, currentVmsData)) {
      return undefined;
    }
    return vmsDataHandler
      .updateVmsBoard({
        vmsConfigId: currentVmsData.vmsConfigId,
        ...updatedVmsFields,
      })
      .then(() => {
        track('VMS Edited', {
          referrer,
          vms: {
            vmsConfigId: currentVmsData.vmsConfigId,
            updatedVmsFields,
          },
        });
      });
  };

  const updateVmsDates = async (
    vmsDates: Pick<ActiveVms, 'startUpdatingAt' | 'stopUpdatingAt'>
  ) => {
    if (!currentVmsData) {
      throw new Error('VMS data is missing');
    }
    if (equalFieldValues(vmsDates, currentVmsData)) {
      return undefined;
    }
    return vmsDataHandler
      .updateVmsSchedule({
        vmsConfigId: Number(vmsConfigId),
        ...vmsDates,
      })
      .then(() => {
        track('VMS Edited', {
          referrer: 'VMS schedule step',
          vms: {
            vmsConfigId: currentVmsData.vmsConfigId,
            vmsDates,
          },
        });
      });
  };

  const [confirmationModalProps, setConfirmationModalProps] =
    useState<ConfirmationModalProps | null>(null);
  const handleDetailsUpdate = (updatedFields: Partial<ActiveVms>) => {
    if (!currentVmsData || !updatedFields?.vmsType) {
      throw new Error('VMS data is missing');
    }
    if (
      updatedFields.vmsType === currentVmsData?.vmsType ||
      !isBoardLessCapable(updatedFields.vmsType, currentVmsData?.vmsType)
    ) {
      return updateVmsData(updatedFields, 'VMS details step');
    }
    return new Promise<void | PreventWizardStepExit>((resolve, reject) => {
      setConfirmationModalProps({
        open: true,
        title: (
          <FormattedMessage
            defaultMessage="Change board manufacturer?"
            id="6W/1Zp"
          />
        ),
        body: (
          <>
            <Box mb={1} fontSize="xs">
              <FormattedMessage
                defaultMessage="Due to differing constraints of VMS manufacturers, changing the make of the board will clear all existing messaging. "
                id="lgMGrR"
              />
            </Box>
            <Box fontSize="xs">
              <FormattedMessage
                defaultMessage="Are you sure want to change the board make?"
                id="O/jdvH"
              />
            </Box>
          </>
        ),
        confirmButtonColorScheme: 'red',
        confirmButtonText: (
          <FormattedMessage
            defaultMessage="Yes, change board make"
            id="dLMqGT"
          />
        ),
        onConfirm: () => {
          if (!currentVmsData) {
            throw new Error('VMS data is missing');
          }
          updateVmsData(
            {
              ...updatedFields,
              ...resetMessageFields({ vmsData: currentVmsData }),
            },
            'VMS details step'
          )
            .then(resolve)
            .catch(reject);
          setConfirmationModalProps(null);
        },
        onCancel: () => {
          setConfirmationModalProps(null);
          resolve('PREVENT_WIZARD_STEP_EXIT');
        },
      });
    });
  };

  const vmsDetailsSaveHandler = (formFields: Partial<ActiveVms>) =>
    currentVmsData
      ? handleDetailsUpdate(formFields)
      : vmsDataHandler.createVmsBoard(formFields).then((response) => {
          navigate(
            pathSiteVmsBoard(insightId, siteId, String(response.vmsConfigId)),
            {
              replace: true,
            }
          );
          track('VMS Created', {
            referrer: 'VMS details step',
            vms: {
              vmsConfigId: response.vmsConfigId,
              vmsName: formFields.vmsName,
            },
          });
        });

  const routeColorsByTrackId = useMemo(
    () => colorsSortedByKey(routesDataHandler.data ?? [], 'trackId'),
    [routesDataHandler.data]
  );
  const nonSelectedRouteColor = useToken('colors', 'gray.400');
  const vmsColors = useMemo(
    () => colorsSortedByKey(vmsDataHandler.data ?? [], 'vmsConfigId'),
    [vmsDataHandler.data]
  );
  const vmsWithColors = useMemo(
    () =>
      vmsDataHandler.data?.map((vm) => ({
        ...vm,
        color: vmsColors[vm.vmsConfigId],
      })),
    [vmsDataHandler.data, vmsColors]
  );

  const currentStep = stepsManager.activeStep;
  const wideSteps: number[] = [
    CreateVmsSteps.MESSAGE_TYPE,
    CreateVmsSteps.OVERRIDE_FALLBACK,
    CreateVmsSteps.SCHEDULE,
  ];

  const isVmsDataLoading = vmsConfigId !== undefined && !currentVmsData;

  const submitBoardDetailsActionRef =
    createRef<() => Promise<void | PreventWizardStepExit>>();
  const submitBoardMessageTypeActionRef = createRef<() => Promise<void>>();
  const submitBoardOverrideFallbackActionRef = createRef<() => Promise<void>>();
  const submitBoardScheduleConnectionActionRef =
    createRef<() => Promise<void | PreventWizardStepExit>>();

  const steps = [
    {
      name: formatMessage({
        defaultMessage: 'Board details',
        id: 'J0YXmZ',
      }),
      title: formatMessage({
        defaultMessage: 'Board details',
        id: 'J0YXmZ',
      }),
      onStepWillExit: () =>
        submitBoardDetailsActionRef
          .current?.()
          .catch(catchValidationErrorAndPreventStepExit),
      content: isVmsDataLoading ? (
        <StepSpinner />
      ) : (
        <StepBoardDetails
          key={currentVmsData?.vmsConfigId}
          submitActionRef={submitBoardDetailsActionRef}
          vms={currentVmsData}
          saveVms={vmsDetailsSaveHandler}
          setMainPanelContent={setMainPanelContent}
          testConnection={vmsDataHandler.testVmsBoardConnection}
          getVmsList={vmsDataHandler.getVmsList}
        />
      ),
    },
    {
      name: formatMessage({
        defaultMessage: 'Message type',
        id: 'gXFgc7',
      }),
      title: formatMessage({
        defaultMessage: 'Messaging type',
        id: 'VWtbCK',
      }),
      onStepWillExit: () =>
        submitBoardMessageTypeActionRef
          .current?.()
          .catch(catchValidationErrorAndPreventStepExit),
      content:
        !currentVmsData || !routesDataHandler.data ? (
          <StepSpinner />
        ) : (
          <StepBoardMessageType
            key={JSON.stringify({
              vmsConfigId: currentVmsData?.vmsConfigId,
              siteId,
            })}
            submitActionRef={submitBoardMessageTypeActionRef}
            siteId={`${siteId}`}
            vms={currentVmsData}
            updateVms={(vmsData) => updateVmsData(vmsData, 'Message Type Step')}
            routes={routesDataHandler.data}
            isAdmin={isAdmin}
            setSelectedRoutes={setSelectedRoutes}
          />
        ),
    },
    {
      name: formatMessage({
        defaultMessage: 'Override & fallback',
        id: 'sHUapc',
      }),
      title: formatMessage({
        defaultMessage: 'Override & fallback',
        id: 'sHUapc',
      }),
      onStepWillExit: () =>
        submitBoardOverrideFallbackActionRef
          .current?.()
          .catch(catchValidationErrorAndPreventStepExit),
      content:
        !currentVmsData || !siteDataHandler.data ? (
          <StepSpinner />
        ) : (
          <StepBoardOverrideFallback
            key={JSON.stringify({
              vmsConfigId: currentVmsData?.vmsConfigId,
              siteId,
            })}
            submitActionRef={submitBoardOverrideFallbackActionRef}
            vms={currentVmsData}
            updateVms={(vmsData) =>
              updateVmsData(vmsData, 'Override & fallback step')
            }
          />
        ),
    },
    {
      name: formatMessage({
        defaultMessage: 'Schedule connection',
        id: '2/LKpR',
      }),
      title: formatMessage({
        defaultMessage: 'Schedule your connection',
        id: '9EEdjB',
      }),
      onStepWillExit: () =>
        submitBoardScheduleConnectionActionRef
          .current?.()
          .catch(catchValidationErrorAndPreventStepExit),
      content:
        !currentVmsData || !siteDataHandler.data ? (
          <StepSpinner />
        ) : (
          <StepBoardScheduleConnection
            key={JSON.stringify({
              vmsConfigId: currentVmsData?.vmsConfigId,
              siteId,
            })}
            submitActionRef={submitBoardScheduleConnectionActionRef}
            vms={currentVmsData}
            updateVmsDates={updateVmsDates}
            siteTimeZone={siteDataHandler.data.siteTimeZone}
          />
        ),
    },
  ];

  return (
    <>
      {confirmationModalProps && (
        <ConfirmationModal {...confirmationModalProps} />
      )}
      <Wrapper
        contentSidebar={
          <WizardLayout
            hideStepper={
              !!(
                currentVmsData?.startUpdatingAt ??
                currentVmsData?.stopUpdatingAt
              )
            }
            stepsManager={stepsManager}
            onExit={goToVmsPage}
            onCancel={goToVmsPage}
            isDisabled={isVmsDataLoading}
            steps={steps}
          />
        }
        layoutSettings={{
          sidebarMode: wideSteps.includes(currentStep) ? 'wide' : 'narrow',
          mapProps: {
            setBoundsToElements: routesDataHandler.data,
          },
        }}
        contentMain={mainPanelContent}
        contentMap={
          <>
            {routesDataHandler?.data?.map((route) => {
              const routeColor =
                currentStep === CreateVmsSteps.MESSAGE_TYPE &&
                !selectedRoutes?.includes(route.routeId.toString())
                  ? nonSelectedRouteColor
                  : routeColorsByTrackId[route.trackId];
              return (
                <Fragment key={route.trackId}>
                  <AnimatedRoute
                    zIndex={2}
                    shouldAnimate
                    animationSpeed={0.5}
                    routeColor={routeColor}
                    routePoints={parseWkt(route.wkt)}
                  />
                </Fragment>
              );
            })}
            {vmsWithColors && (
              <MapLayerVmsBoardsWithPreview
                vmsBoards={vmsWithColors}
                orgId={insightId}
                siteId={siteId}
                deleteVmsBoard={vmsDataHandler.deleteVmsBoard}
              />
            )}
          </>
        }
      />
    </>
  );
}

function StepSpinner() {
  return (
    <Flex
      width="100%"
      height="100%"
      maxHeight="160px"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner variant="mooven" size="xl" />
    </Flex>
  );
}
