import './ag-theme-override.scss';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { checkboxStyleDefault, themeQuartz } from 'ag-grid-community';
import { useTheme } from '@chakra-ui/react';

const defaulColDef = {
  filter: true,
  flex: 0.1,
  minWidth: 150,
};

export function DataGrid(props: AgGridReactProps) {
  const chakraTheme = useTheme();
  const myTheme = themeQuartz
    .withParams({
      fontSize: chakraTheme.fontSizes.sm,
      fontFamily: chakraTheme.fonts.body,
      rowHoverColor: chakraTheme.colors.gray[50],
      accentColor: chakraTheme.colors.green[500],
      foregroundColor: chakraTheme.colors.gray[700],
      headerBackgroundColor: chakraTheme.colors.gray[50],
      headerTextColor: chakraTheme.colors.gray[900],
      borderColor: chakraTheme.colors.gray[200],
      spacing: chakraTheme.space[2],
      selectedRowBackgroundColor: chakraTheme.colors.green[50],
      inputFocusShadow: `0 0 0 1px ${chakraTheme.colors.green[500]}`,
      wrapperBorderRadius: chakraTheme.radii.xl,
      borderRadius: chakraTheme.radii.md,
      popupShadow: chakraTheme.shadows.soft,
      inputDisabledBackgroundColor: chakraTheme.colors.gray[900],
    })
    .withPart(
      checkboxStyleDefault.withParams({
        checkboxBorderRadius: chakraTheme.radii.sm,
      }).withCSS(`
        .ag-checkbox-input-wrapper.ag-disabled {
          filter: none;
      `)
    );

  return (
    <AgGridReact theme={myTheme} defaultColDef={defaulColDef} {...props} />
  );
}
