import { LatLng } from '@lib/gis';

export interface ProjectItem {
  configId: string;
  site: {
    siteId: string;
    siteName: string;
    siteAddress: string;
    siteLocation: LatLng;
    siteTimeZone: string;
  };
  workArea: number;
  note: string;
  currentPhase: string;
  phases: { startTime: number; endTime: number; phaseType: string }[];
  assetTypes: string[];
}

const mockData: ProjectItem[] = [
  {
    configId: '1',
    site: {
      siteId: '1',
      siteName: 'Site 1',
      siteLocation: {
        lat: 0,
        lng: 0,
      },
      siteAddress: 'Address of Site 1',
      siteTimeZone: 'Pacific/Auckland',
    },
    workArea: 100,
    note: 'Note 1',
    currentPhase: 'Phase 1',
    phases: [
      {
        startTime: 1728401301348,
        endTime: 1728661351348,
        phaseType: 'Type 1',
      },
    ],
    assetTypes: ['Type 1'],
  },
  {
    configId: '2',
    site: {
      siteId: '2',
      siteName: 'Site 2',
      siteLocation: {
        lat: 0,
        lng: 0,
      },
      siteAddress: 'Address of Site 2',
      siteTimeZone: 'Pacific/Auckland',
    },
    workArea: 200,
    note: 'Note 2',
    currentPhase: 'Phase 2',
    phases: [
      {
        startTime: 1720461301348,
        endTime: 1724461351348,
        phaseType: 'Type 2',
      },
      {
        startTime: 1728461351348,
        endTime: 1738461381348,
        phaseType: 'Type 3',
      },
    ],
    assetTypes: ['Type 2'],
  },
];

export default function useOrgProjectsData({ orgId }: { orgId?: string }) {
  if (!orgId) {
    return {
      data: undefined,
      isLoading: false,
      error: undefined,
    };
  }
  console.log(`Getting mock projects for org ${orgId}`);
  return {
    data: mockData,
    isLoading: false,
    error: undefined,
  };
}
