import { useState } from 'react';
import { FormattedMessage, IntlFormatters, useIntl } from 'react-intl';
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Switch,
  Text,
} from '@chakra-ui/react';
import moment from 'moment-timezone';
import { InsightsIcon } from 'design-system/atoms/custom-icons';
import DateRangeSelection from 'design-system/molecules/date-range-selection';
import { DATE_PRESET_SHORT_D_M_Y } from 'design-system/utilities/date-presets';
import { TimePeriod } from '../../../types/timePeriod';
import ComparisonDateSelectionModal from './ComparisonDateSelectionModal';
import { FEATURE_DATE_RANGE_V2 } from '../../../constants/features';
import useFeatureSwitch, {
  FeatureSwitchState,
} from '../../../hooks/useFeatureSwitch';

export type TrackingChangeField =
  | 'period'
  | 'comparedate'
  | 'compare_on'
  | 'customdate'
  | 'compare_off';

interface ViewTimeSelectionProps {
  timePeriod: TimePeriod;
  setTimePeriod: (
    timePeriod: TimePeriod,
    trackingDescription: TrackingChangeField
  ) => void;
  timeZone: string;
  startMonitoringDate?: number;
}

enum TimeOfDay {
  START,
  NOW,
  END,
}

const momentSubtractParameterString = {
  TIME_PERIOD_LAST_24_HOURS: 'days' as moment.unitOfTime.DurationConstructor,
  TIME_PERIOD_LAST_WEEK: 'weeks' as moment.unitOfTime.DurationConstructor,
  TIME_PERIOD_LAST_MONTH: 'months' as moment.unitOfTime.DurationConstructor,
};

export default function ViewTimeSelection({
  timePeriod,
  setTimePeriod,
  timeZone,
  startMonitoringDate,
}: ViewTimeSelectionProps) {
  const { formatDate } = useIntl();
  const [isEditingDateRange, setEditingDateRange] = useState(false);
  const [isEditingComparisonDate, setEditingComparisonDate] = useState(false);
  const featureDateRangeV2 = useFeatureSwitch(FEATURE_DATE_RANGE_V2);

  return (
    <Box>
      <Box mb={2}>
        <InsightsIcon width={6} height={6} mr={2} />
        <Text fontSize="sm" as="span">
          <FormattedMessage
            defaultMessage="<strong>Insights</strong> - Show the last..."
            id="4c0F9K"
            description="View time selection controls title"
          />
        </Text>
      </Box>
      <Box pb={2}>
        <ButtonGroup width="100%" size="sm" isAttached mb={2}>
          <Button
            onClick={() => {
              setTimePeriod({ type: 'TIME_PERIOD_LAST_24_HOURS' }, 'period');
            }}
            colorScheme={
              timePeriod.type === 'TIME_PERIOD_LAST_24_HOURS'
                ? 'greenDark'
                : 'gray'
            }
            variant={
              timePeriod.type === 'TIME_PERIOD_LAST_24_HOURS'
                ? 'solid'
                : 'outline'
            }
          >
            <FormattedMessage
              defaultMessage="24hrs"
              id="SLTXQm"
              description="View time selection controls button label - 24 hours"
            />
          </Button>
          <Button
            onClick={() => {
              setTimePeriod({ type: 'TIME_PERIOD_LAST_WEEK' }, 'period');
            }}
            colorScheme={
              timePeriod.type === 'TIME_PERIOD_LAST_WEEK' ? 'greenDark' : 'gray'
            }
            variant={
              timePeriod.type === 'TIME_PERIOD_LAST_WEEK' ? 'solid' : 'outline'
            }
          >
            <FormattedMessage
              defaultMessage="Week"
              id="8iburl"
              description="View time selection controls button label - week"
            />
          </Button>
          <Button
            onClick={() => {
              setTimePeriod({ type: 'TIME_PERIOD_LAST_MONTH' }, 'period');
            }}
            colorScheme={
              timePeriod.type === 'TIME_PERIOD_LAST_MONTH'
                ? 'greenDark'
                : 'gray'
            }
            variant={
              timePeriod.type === 'TIME_PERIOD_LAST_MONTH' ? 'solid' : 'outline'
            }
          >
            <FormattedMessage
              defaultMessage="Month"
              id="KrlIe2"
              description="View time selection controls button label - month"
            />
          </Button>
          <Button
            onClick={() => setEditingDateRange(true)}
            data-testid="view-time-selection-period-custom"
            colorScheme={
              timePeriod.type === 'TIME_PERIOD_CUSTOM' ? 'greenDark' : 'gray'
            }
            variant={
              timePeriod.type === 'TIME_PERIOD_CUSTOM' ? 'solid' : 'outline'
            }
          >
            <FormattedMessage
              defaultMessage="Custom"
              id="lMM4de"
              description="View time selection controls button label - custom"
            />
          </Button>
        </ButtonGroup>
        <DateRangeSelection
          useV2={featureDateRangeV2 === FeatureSwitchState.ON}
          key={isEditingDateRange ? 0 : 1}
          minDate={
            startMonitoringDate
              ? dateToString(startMonitoringDate, timeZone)
              : undefined
          }
          maxDayRange={60}
          infoText={
            startMonitoringDate ? (
              <Text fontSize="xs" m={0}>
                <Text as="span" fontWeight="bold" mr={2}>
                  <FormattedMessage
                    defaultMessage="Monitoring started:"
                    id="gX7JqY"
                    description="Monitoring started label"
                  />
                </Text>
                {formatDate(startMonitoringDate, {
                  timeZone,
                  ...DATE_PRESET_SHORT_D_M_Y,
                })}
              </Text>
            ) : undefined
          }
          isOpen={isEditingDateRange}
          value={
            timePeriod.type === 'TIME_PERIOD_CUSTOM'
              ? {
                  startDate: dateToString(timePeriod.startDate, timeZone),
                  endDate: dateToString(timePeriod.endDate, timeZone),
                }
              : {
                  startDate: moment()
                    .tz(timeZone)
                    .subtract(1, momentSubtractParameterString[timePeriod.type])
                    .format('YYYY-MM-DD'),
                  endDate: dateToString(Date.now(), timeZone),
                }
          }
          onConfirm={({ startDate, endDate }) => {
            setTimePeriod(
              {
                type: 'TIME_PERIOD_CUSTOM',
                startDate: stringToDate(startDate!, timeZone),
                endDate: stringToDate(endDate!, timeZone, TimeOfDay.END),
              },
              'customdate'
            );
            setEditingDateRange(false);
          }}
          onCancel={() => {
            setEditingDateRange(false);
          }}
        />
        {timePeriod.type === 'TIME_PERIOD_CUSTOM' && (
          <Button
            backgroundColor="white"
            p={2}
            mb={2}
            fontSize="sm"
            _hover={{
              backgroundColor: 'white',
              boxShadow: 'md',
            }}
            onClick={() => {
              setEditingDateRange(true);
            }}
          >
            {formatDate(timePeriod.startDate, {
              timeZone,
              ...DATE_PRESET_SHORT_D_M_Y,
            })}
            {' - '}
            {formatDate(timePeriod.endDate, {
              timeZone,
              ...DATE_PRESET_SHORT_D_M_Y,
            })}
          </Button>
        )}
      </Box>
      <FormControl display="flex" alignItems="center" mb={2}>
        <Switch
          size="sm"
          mb={0}
          colorScheme="green"
          data-testid="view-time-selection-compare-switch"
          onChange={() => {
            if (timePeriod.compareDate !== undefined) {
              setTimePeriod(
                { ...timePeriod, compareDate: undefined },
                'compare_off'
              );
            } else {
              setEditingComparisonDate(true);
            }
          }}
          isChecked={timePeriod.compareDate !== undefined}
        />
        <FormLabel mb={0} ml={2} fontSize="sm" fontWeight={400}>
          <FormattedMessage
            defaultMessage={`
                Compare
                {selectedPeriodType, select,
                  TIME_PERIOD_LAST_24_HOURS {day}
                  TIME_PERIOD_LAST_WEEK {week}
                  TIME_PERIOD_LAST_MONTH {month}
                  other {period}
                }
              `}
            id="QFA/Bn"
            values={{
              selectedPeriodType: timePeriod.type,
            }}
            description="View time selection controls label - compare day"
          />
        </FormLabel>
      </FormControl>
      {timePeriod.compareDate !== undefined && (
        <Button
          backgroundColor="white"
          p={2}
          mb={2}
          fontSize="sm"
          _hover={{
            backgroundColor: 'white',
            boxShadow: 'md',
          }}
          onClick={() => {
            setEditingComparisonDate(true);
          }}
        >
          {formatCompareDateRange(formatDate, timePeriod, timeZone)}
        </Button>
      )}
      <ComparisonDateSelectionModal
        key={isEditingComparisonDate ? 0 : 1}
        isOpen={isEditingComparisonDate}
        dateStr={getInitialComparisonDate(timePeriod, timeZone)}
        onConfirm={(dateStr) => {
          setTimePeriod(
            {
              ...timePeriod,
              compareDate: stringToDate(
                dateStr,
                timeZone,
                timePeriod.type === 'TIME_PERIOD_CUSTOM'
                  ? TimeOfDay.START
                  : TimeOfDay.NOW
              ),
            },
            timePeriod.compareDate ? 'comparedate' : 'compare_on'
          );
          setEditingComparisonDate(false);
        }}
        onCancel={() => {
          setEditingComparisonDate(false);
        }}
      />
    </Box>
  );
}

const DAY_IN_MS = 1000 * 60 * 60 * 24;

function formatCompareDateRange(
  formatDate: IntlFormatters['formatDate'],
  timePeriod: TimePeriod,
  timeZone
) {
  if (timePeriod.compareDate === undefined) {
    return '';
  }
  const compareDateStart = formatDate(timePeriod.compareDate, {
    timeZone,
    ...DATE_PRESET_SHORT_D_M_Y,
  });
  if (timePeriod.type === 'TIME_PERIOD_LAST_24_HOURS') {
    return compareDateStart;
  }
  const mutableCompareEndMoment = moment(timePeriod.compareDate).tz(timeZone);
  switch (timePeriod.type) {
    case 'TIME_PERIOD_LAST_WEEK':
      mutableCompareEndMoment.add(1, 'week');
      break;
    case 'TIME_PERIOD_LAST_MONTH':
      mutableCompareEndMoment.add(1, 'month');
      break;
    case 'TIME_PERIOD_CUSTOM':
      mutableCompareEndMoment.add(
        Math.floor((timePeriod.endDate - timePeriod.startDate) / DAY_IN_MS),
        'days'
      );
      break;
    default:
      break;
  }
  return `${compareDateStart} - ${formatDate(
    mutableCompareEndMoment.valueOf(),
    { timeZone, ...DATE_PRESET_SHORT_D_M_Y }
  )}`;
}

function getInitialComparisonDate(timePeriod: TimePeriod, timeZone) {
  if (timePeriod.compareDate) {
    return dateToString(timePeriod.compareDate, timeZone);
  }
  if (timePeriod.type === 'TIME_PERIOD_CUSTOM') {
    return moment(timePeriod.startDate)
      .tz(timeZone)
      .subtract(1, 'week')
      .format('YYYY-MM-DD');
  }
  return moment()
    .tz(timeZone)
    .subtract(2, momentSubtractParameterString[timePeriod.type])
    .format('YYYY-MM-DD');
}

function stringToDate(
  strDate: string,
  timeZone: string,
  time: TimeOfDay = TimeOfDay.START
) {
  const momentDate = moment.tz(strDate, timeZone);
  if (time === TimeOfDay.END) {
    momentDate
      .add(23, 'hours')
      .add(59, 'minutes')
      .add(59, 'seconds')
      .add(999, 'milliseconds');
  } else if (time === TimeOfDay.NOW) {
    const now = moment().tz(timeZone);
    momentDate.add(now.hours(), 'hours').add(now.minutes(), 'minutes');
  }
  return momentDate.valueOf();
}

function dateToString(date: number, timeZone: string) {
  return moment(date).tz(timeZone).format('YYYY-MM-DD');
}
