import { FormattedMessage } from 'react-intl';
import { ActiveVms, VendorType } from '@webapp/bff/src/types/vms';
import { MessageTypeConfig } from '@configuration/lib-vms/src/message-types';
import { pathSiteVmsBoard } from '../../constants/path';
import { BoardCapabilities } from './types';

export enum CreateVmsSteps {
  DETAILS,
  MESSAGE_TYPE,
  OVERRIDE_FALLBACK,
  SCHEDULE,
}

export function getDefaultBoardMessage(routeId: string) {
  return {
    key: `autoBalancing-default-frame-${routeId}`,
    lines: [
      {
        key: 'autoBalancing-default-frame-1-line-1',
        lineParts: [{ type: 'text', content: 'CITY' }],
      },
      {
        key: 'autoBalancing-default-frame-1-line-2',
        lineParts: [
          {
            type: 'journeyTime',
            content: `${routeId}`,
          },
          { type: 'text', content: ' min' },
        ],
      },
    ],
  };
}
export const getCurrentSetupStep = (vmsBoard?: ActiveVms) => {
  if (!vmsBoard?.vmsName) {
    return CreateVmsSteps.DETAILS;
  }

  const connectFields = [vmsBoard.vmsType, vmsBoard.vmsId];

  if (vmsBoard.vmsType === 'Datasigns' || vmsBoard.vmsType === 'J1') {
    connectFields.push(vmsBoard.userEmail);
  }

  const connectFieldsFilled = connectFields.filter(Boolean).length;
  if (connectFieldsFilled === 0 || connectFieldsFilled < connectFields.length) {
    return CreateVmsSteps.DETAILS;
  }
  if (!vmsBoard.messageConfig || !vmsBoard.monitoredRoutes) {
    return CreateVmsSteps.MESSAGE_TYPE;
  }

  if (!vmsBoard.fallbackMessageConfig || !vmsBoard.overrideScheduleConfig) {
    return CreateVmsSteps.OVERRIDE_FALLBACK;
  }

  if (!vmsBoard.startUpdatingAt || !vmsBoard.stopUpdatingAt) {
    return CreateVmsSteps.SCHEDULE;
  }
  return CreateVmsSteps.DETAILS;
};

export function getVmsContextMenuItems({
  orgId,
  siteId,
  vmsConfigId,
  onOpenDeleteModal,
  handleOpenPreview,
  hasSetupCompleted,
  currentStep,
}: {
  orgId: string;
  siteId: string;
  vmsConfigId: string;
  onOpenDeleteModal: (vmsConfigId: string) => void;
  handleOpenPreview?: (vmsConfigId: string) => Promise<void>;
  hasSetupCompleted?: boolean;
  currentStep?: CreateVmsSteps;
}) {
  const baseOptions = [
    {
      key: 'delete board',
      label: <FormattedMessage defaultMessage="Delete board" id="9nekft" />,
      action: () => onOpenDeleteModal(vmsConfigId),
    },
  ];

  const setupOptions = hasSetupCompleted
    ? [
        {
          key: 'edit board name',
          label: <FormattedMessage defaultMessage="Rename board" id="ivDbr1" />,
          action: `${pathSiteVmsBoard(orgId, siteId, vmsConfigId)}?step=details`,
        },
        {
          key: 'edit board credentials',
          label: (
            <FormattedMessage defaultMessage="Update connection" id="qFfWnO" />
          ),
          action: `${pathSiteVmsBoard(orgId, siteId, vmsConfigId)}?step=connect`,
        },
        {
          key: 'edit message type',
          label: <FormattedMessage defaultMessage="Edit message" id="jRANBd" />,
          action: `${pathSiteVmsBoard(
            orgId,
            siteId,
            vmsConfigId
          )}?step=message_type`,
        },
        {
          key: 'edit override and fallback',
          label: (
            <FormattedMessage
              defaultMessage="Edit Override & Fallback"
              id="r0utO8"
            />
          ),
          action: `${pathSiteVmsBoard(
            orgId,
            siteId,
            vmsConfigId
          )}?step=override_fallback`,
        },
        {
          key: 'edit schedule',
          label: (
            <FormattedMessage defaultMessage="Edit schedule" id="VcGmPo" />
          ),
          action: `${pathSiteVmsBoard(orgId, siteId, vmsConfigId)}?step=schedule`,
        },
      ]
    : [
        {
          key: 'continue setup',
          label: (
            <FormattedMessage defaultMessage="Continue setup" id="LrtR4w" />
          ),
          action: `${pathSiteVmsBoard(orgId, siteId, vmsConfigId)}?step=${
            currentStep !== undefined
              ? CreateVmsSteps[currentStep].toLowerCase()
              : 'details'
          }`,
        },
        {
          key: 'edit board credentials',
          label: (
            <FormattedMessage defaultMessage="Update connection" id="qFfWnO" />
          ),
          action: `${pathSiteVmsBoard(orgId, siteId, vmsConfigId)}?step=connect`,
        },
      ];

  const previewOption = handleOpenPreview
    ? [
        {
          key: 'open preview',
          label: <FormattedMessage defaultMessage="Open preview" id="97zyiz" />,
          action: async () => {
            await handleOpenPreview(vmsConfigId);
          },
        },
      ]
    : [];

  const options = [...setupOptions, ...baseOptions, ...previewOption];

  return options;
}

export function equalFieldValues(
  updatedFields: Partial<ActiveVms>,
  currentVmsData: ActiveVms
) {
  return Object.keys(updatedFields).every(
    (key) =>
      JSON.stringify(updatedFields[key]) === JSON.stringify(currentVmsData[key])
  );
}

const boardCapabilities = {
  [VendorType.Bartco]: {
    maxLineCharCount: 12,
    maxLineCount: 4,
  },
  [VendorType.Datasigns]: {
    maxLineCharCount: 12,
    maxLineCount: 4,
  },
  [VendorType.VirtualVms]: {
    maxLineCharCount: 12,
    maxLineCount: 4,
  },
  [VendorType['J1-LED']]: {
    maxLineCharCount: 8,
    maxLineCount: 3,
  },
};
export function getBoardCapabilities(
  vmsType: VendorType = VendorType.VirtualVms
): BoardCapabilities {
  return boardCapabilities[vmsType];
}
export function isBoardLessCapable(
  boardTypeA: VendorType,
  boardTypeB?: VendorType
) {
  if (!boardTypeB) {
    return false;
  }
  return (
    boardCapabilities[boardTypeA].maxLineCharCount <
      boardCapabilities[boardTypeB].maxLineCharCount ||
    boardCapabilities[boardTypeA].maxLineCount <
      boardCapabilities[boardTypeB].maxLineCount
  );
}

export function resetMessageFields({
  vmsData,
}: {
  vmsData: ActiveVms;
}): Pick<
  ActiveVms,
  'messageConfig' | 'fallbackMessageConfig' | 'overrideScheduleMessageConfig'
> {
  let messageConfigContent: MessageTypeConfig['content'] | undefined;
  if (vmsData.messageConfig?.type === 'LiveJourneyTime') {
    messageConfigContent = [];
  }
  if (vmsData.messageConfig?.type === 'AutomatedLoadBalancing') {
    messageConfigContent = vmsData.messageConfig.content && {
      ...vmsData.messageConfig.content,
      messages:
        vmsData.messageConfig.content.messages?.map((m) => ({
          ...m,
          message: [],
        })) ?? [],
    };
  }
  if (vmsData.messageConfig?.type === 'AdvancedMode') {
    messageConfigContent = '';
  }
  return {
    messageConfig: vmsData.messageConfig && {
      ...vmsData.messageConfig,
      content: messageConfigContent,
    },
    fallbackMessageConfig: [],
    overrideScheduleMessageConfig: [],
  };
}
