import useSWR from 'swr';
import { envConfig } from '../config/envConfig';

export type Org = {
  name: string;
  insightId: number;
};

export default function useOrgsData() {
  const { data, error, isValidating } = useSWR<Org[]>(
    `${envConfig.API_URL}/api/v1/insights`
  );
  return {
    data,
    error,
    isValidating,
  };
}
