import { SearchIcon, CheckIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
  useDisclosure,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import { Path, Link, useLocation, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useOrgsData, { Org } from '../../data/useOrgsData';

interface Props {
  orgs: Org[];
  selectedOrg: number;
  projectLinkBuilder: (projectId: number) => string | Partial<Path>;
}

export function useOrgSelectorManager() {
  const params = useParams();
  const location = useLocation();
  const orgsDataHandler = useOrgsData();
  if (params.insightId === undefined) {
    return null;
  }
  const projectLinkBuilder = (newInsightId: number) => ({
    ...location,
    pathname: location.pathname.replace(
      new RegExp(`/${params.insightId}($|/)`),
      (match, capture1) => `/${newInsightId}${capture1}`
    ),
  });
  return {
    selectedOrg: parseInt(params.insightId, 10),
    orgs: orgsDataHandler.data ?? [],
    projectLinkBuilder,
  };
}

export default function OrgSelector({
  orgs,
  selectedOrg,
  projectLinkBuilder,
}: Props) {
  const [searchTerm, setSearchTerm] = useState('');
  const { isOpen, onClose, onOpen } = useDisclosure();
  const isFullScreen = useBreakpointValue({
    md: false,
    base: true,
  });
  const processedProjects = orgs
    .filter(
      (p) =>
        !searchTerm.trim() ||
        p.name.trim().toLowerCase().indexOf(searchTerm.trim().toLowerCase()) >=
          0
    )
    .sort((p1, p2) => p1.name.localeCompare(p2.name));
  const searchRef = useRef<HTMLInputElement>();
  useEffect(() => {
    onClose();
  }, [selectedOrg, onClose]);
  useEffect(() => {
    searchRef.current?.focus();
  }, [isOpen]);
  return (
    <>
      <Button
        onClick={onOpen}
        size="sm"
        variant="solid"
        colorScheme="gray"
        color="gray.700"
        layerStyle="floating"
        backgroundColor="white"
        _hover={{
          textDecoration: 'none',
          color: 'gray.700',
          backgroundColor: 'gray.50',
        }}
        maxW="200px"
        minW="160px"
      >
        <Text width="full" textOverflow="ellipsis" overflow="hidden" mb={0}>
          {orgs.find((p) => p.insightId === selectedOrg)?.name}
        </Text>
      </Button>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        size={isFullScreen ? 'full' : undefined}
      >
        <ModalOverlay backgroundColor="whiteAlpha.800" />
        <ModalContent h="75vh">
          <ModalHeader>
            <VStack w="full" direction="column" alignItems="flex-start">
              <Box>
                <FormattedMessage
                  defaultMessage="Select an organization"
                  id="MPQ0kS"
                />
              </Box>
              <InputGroup size="sm" mr={2}>
                <Input
                  type="search"
                  ref={(element) => {
                    searchRef.current = element as HTMLInputElement;
                  }}
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <InputRightElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputRightElement>
              </InputGroup>
            </VStack>
          </ModalHeader>
          <ModalCloseButton backgroundColor="gray.100" />
          <ModalBody overflowY="auto">
            <Flex w="full" direction="column" alignItems="flex-start">
              <Flex direction="column" overflowY="auto" flexGrow={1}>
                {processedProjects.map((p) => (
                  <OrgLine
                    key={p.insightId}
                    org={p}
                    selectedOrg={selectedOrg}
                    projectLinkBuilder={projectLinkBuilder}
                  />
                ))}
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter justifyContent="start">
            <Button
              data-testid="project-selection-cancel"
              variant="outline"
              mr={2}
              onClick={onClose}
            >
              <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function OrgLine({
  org,
  selectedOrg,
  projectLinkBuilder,
}: {
  org: Org;
  selectedOrg: number;
  projectLinkBuilder: (newInsightId: number) => string | Partial<Path>;
}) {
  const saveSelectionToLocalStorage = () => {
    localStorage.setItem('last-project-selected', org.insightId.toString());
  };
  const [hovered, setHovered] = useState(false);
  return (
    <HStack mt={4} spacing={4}>
      <Box w="40px" h="40px" minWidth="40px">
        <OrgLineSymbol
          isSelected={org.insightId === selectedOrg}
          isHovered={hovered}
        />
      </Box>
      <Link
        to={projectLinkBuilder(org.insightId)}
        onClick={saveSelectionToLocalStorage}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Box flexGrow={1}>
          <Wrap>
            <Text fontSize="md" fontWeight="bold" overflowWrap="break-word">
              {org.name}
            </Text>
          </Wrap>
        </Box>
      </Link>
    </HStack>
  );
}

function OrgLineSymbol({
  isSelected,
  isHovered,
}: {
  isSelected: boolean;
  isHovered: boolean;
}) {
  if (!isSelected && !isHovered) {
    return null;
  }
  return (
    <Flex
      w="40px"
      h="40px"
      minWidth="40px"
      justifyContent="center"
      alignItems="center"
      borderRadius="full"
      bgColor={isSelected ? 'green.100' : 'green.50'}
    >
      <CheckIcon
        boxSize="18px"
        color={isSelected ? 'green.600' : 'green.300'}
      />
    </Flex>
  );
}
